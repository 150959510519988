<template>
    <div class="cart-page">
        <div id="logo">
            <img :src="image('tv_sh_logo.png')" alt=""></div>
        <div id="subWrap">
            <div id="eoMainWrap">
                <img id="header" :src="image('WinAgreement.png')"></div>
            <div id="eoDevice" class="devices">
                <div class="device">
                    <div id="eoWrap" class="spectrumControlled">
                        <div v-if="cart.quantity > 0"
                             class="eoSections eoSection-2 eoOrderSection eo-cart-with-order-summary">
                            <div class="eoSubSection">
                                <div class="eoOrderAreaHead"><p>Shopping Cart</p></div>
                                <div class="eo-noOrder" style="display: none;">
                                    <p class=" orderPersonalization">I, <span class="replay replay_fname">malika</span>&nbsp;<span
                                            class="replay replay_lname">khannous</span>,&nbsp;am forfeiting my customer
                                        benefits - 100% Satisfaction Guarantee, Safe Online Shopping and Fast Order
                                        Processing. I have not selected an item to order below.</p>
                                    <div class="cartHeader">
                                        <div class="cartHeader_noOrder">
                                            <p>malika, YOUR CART IS EMPTY.<br>WON'T YOU RECONSIDER?</p>
                                            <h4>AN ORDER WOULD BE APPRECIATED</h4>
                                        </div>
                                    </div>
                                </div>
                                <div class="sptrm-eo-cco-cart eoCart">
                                    <div class="cartItemThemeBackgroundColor sptrm-eo-cco-cart-headerWrap">
                                        <p class="sptrm-eo-eoCartCount">{{cart.quantity }}</p>
                                        <p class="sptrm-eo-cartTxt">&nbsp;Item(s) in Cart:</p></div>
                                    <div class="eoCartItemsList">
                                        <ol>
                                            <li v-for="item in cart.items"
                                                :key="item.id" class="purchase cartItem_0">
                                                <div class="cartItemWrap">
                                                    <div class="sptrm-eo-cartItemSubwrap">
                                                        <div class="sptrm-eo-cartItem-top">
                                                            <div class="sptrm-eo-cartItem-continueLeft"><p
                                                                    class="sptrm-eo-cartItemMessage"></p>
                                                                <p class="cartItemThemeColor sptrm-eo-cco-cartItemText sptrm-eo-emptyCartItemMessage">
                                                                    Place an order today!</p></div>
                                                            <div class="sptrm-eo-cartItem-bodyWrap">
                                                                <div class="sptrm-eo-cartItem-textPriceWrap">
                                                                    <div class="sptrm-eo-cco-cartItemText  cartItemThemeColor">
                                                                        {{item.product.name}}
                                                                    </div>
                                                                </div>
                                                                <div class="sptrm-eo-cartItem-qtyWrap">
                                                                    <div class="sptrm-eo-cartItem-qtyText">Qty</div>
                                                                    <div class="sptrm-eo-cartItem-qtyBtnWrap">
                                                                        <!--<a
                                                                            href="javascript:void(0);"
                                                                            class="sptrm-eo-cartItem-actionBtns sptrm-eo-cartItem-actionBtnRemove"
                                                                            id="decrement_9LZE" data-action="decrement"
                                                                            onclick="updateQuantity(this,'0');return false;">
                                                                        </a>

                                                                        <input class="quantity" type="number"
                                                                               v-model=item.product.quantity
                                                                               v-on:click="updateCart()"
                                                                               onclick="updateCart()" title="Qty"
                                                                               size="4" placeholder=""
                                                                               inputmode="numeric" autocomplete="off"/>-->
                                                                        <input
                                                                                type="number"
                                                                                :value="item.qty"
                                                                                min="1"
                                                                                :max="item.product.manage_stock ? item.product.qty : ''"
                                                                                class="quantity"
                                                                                @input="updateQuantity(item, $event.target.value)"
                                                                                @keydown.up="updateQuantity(item, item.qty + 1)"
                                                                                @keydown.down="updateQuantity(item, item.qty - 1)"
                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div class="cartItemPricing sptrm-eo-cartItem-pricingRight">
                                                                    <div class=" sptrm-eo-cartItem-pricingRightText">
                                                                        Price:
                                                                    </div>
                                                                    <div class="sptrm-eo-cartItem-pricingRightPrice  cartItemThemeColor">
                                                                        $ {{ item.product.price }}.00
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="sptrm-eo-cartItem-continueRight"><span
                                                                    class="continueShopping cartItemThemeColor">Continue Shopping</span><span
                                                                    class="takeAnotherLook cartItemThemeBackgroundColor"><div
                                                                    class="leftArrow">‹ </div>Take Another Look</span><span
                                                                    class="emptyCart cartItemThemeBackgroundColor ">Continue Shopping</span>
                                                            </div>
                                                            <div class="sptrm-eo-cartItem-deleteWrap">
                                                                <div class="sptrm-eo-trashBtn"
                                                                     @click.prevent="remove(item)"></div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </li>
                                        </ol>
                                        <ul id="summary-list">
                                            <li class="summary-list-item">
                                                <div class="summary-list-item_content summary-list_item-sub"><span>Items({{cart.quantity}}):</span><span>{{cart.subTotal.formatted}}</span>
                                                </div>
                                            </li>
                                            <li class="summary-list-item">
                                                <div class="summary-list-item_content summary-list_total"><span>Total:</span><span>{{cart.total.formatted}}</span>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <h6 id="FPPflatPricing" class="bmlplus" style="display: block;">Pay now or pay later in
                                    5 easy installments of $4.79!</h6>
                                <div class="order-bellow" :class="{ 'mb-3': !isMobile, 'mb-1': isMobile}" >
                                    <p><span>CLICK BELOW TO SUBMIT YOUR ORDER!</span></p>
                                </div>
                                <div class="border-separator-2 mb-0-2"></div>
                                <div class="border-separator-2"></div>
                                <div class="prize-acknowledge mt-3">
                                    <p class="title-prize">Prize Acknowledgment</p>
                                    <div class="paContent">
                                        <p>I, <span v-text="first_name"></span>, with a timely entry, am hereby claiming full eligibility rights to my unique prize number(s) to be issued from this Notice. I would like to win the prize(s) listed below.</p>
                                        <div class="gift">
                                            <img src="/images/main/gift-card-1.jpg" class="img-responsive"/>
                                            <p><span>Giveaway Number(s) 18523</span></p>
                                        </div>
                                    </div>
                                </div>
                                <div class="border-separator-1 mb-0-2"></div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="eoSections eoOrderSection eoSection-2">
                                <div class="eoSubSection">
                                    <div class="eoOrderAreaHead"><p>Shopping Cart</p></div>
                                    <div class="eo-noOrder" style="display: block;">
                                        <p class=" orderPersonalization">I, <span
                                                class="replay replay_fname" v-text="first_name">Firstname</span>, am forfeiting my
                                            customer benefits - 100% Satisfaction Guarantee, Safe Online Shopping and
                                            Fast Order Processing. I have not selected an item to order below.</p>
                                        <div class="cartHeader">
                                            <div class="cartHeader_noOrder">
                                                <p><span v-text="first_name"></span>, YOUR CART IS EMPTY.<br>WON'T YOU RECONSIDER?</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="eoOrderAreaSubWrap">
                                        <div class="cartHeader">
                                            <div class="cartHeader_order">
                                                <a :href="route('home')" class="btn btn-warning">Continue Shopping</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="eoNpnContainer" v-if="cart.quantity > 0">
                            <div id="eoNpnCopy" class="eoDisclaimer">
                                <div id="spctrmCopy1_divPCHComponent">
                                    <div id="spctrmCopy1_ctl00_outerDiv">
                                        <p>*For Magazine Offers, applicable sales tax will be added in WA, CA, and
                                            ME.</p>
                                        <p><strong>NO PURCHASE OR FEE NECESSARY TO ENTER.<br>A PURCHASE WON'T IMPROVE AN
                                            INDIVIDUAL'S CHANCE OF WINNING.</strong></p>
                                    </div>
                                </div>

                                <p></p>
                            </div>
                        </div>
                        <div id="eoSection-2-Btn" v-if="cart.quantity > 0">
                            <!--<a :href="checkoutLink" id="submitButton" class="buttonOrder"></a>-->
                            <form :action="checkoutLink" method="post">
                                <input type="hidden" name="_token" :value="csrfToken"/>
                                <input type="submit" id="submitButton" value="" class="buttonOrder" aria-label="Place Order and Continue">
                            </form>
                        </div>

                        <ul class="SpectrumOptinList evergageVisible"></ul>
                        <div id="eoFooter">
                            <div id="eoDisclaimer" class="eoDisclaimer">
                                <div id="spctrmCopy2_divPCHComponent">
                                    <div id="spctrmCopy2_ctl00_outerDiv">
                                        <p>Your Merchandise order should arrive in about 2 to 4 weeks.<br>For
                                            information relating to timing and delivery of Magazines <a
                                                    href="http://pch.custhelp.com/app/answers/detail/a_id/134"
                                                    target="_blank">click here</a>.</p>

                                        <p>All savings on Magazines are off cover price, except where otherwise
                                            stated.</p>

                                        <p>Free gifts with magazine subscriptions are supplied by the magazine
                                            publishers. They are not available in stores. No maximum retail value
                                            established.</p>

                                        <p>Publishers Clearing House reserves the right to reject any order under our
                                            acceptance criteria which may include internal and third party marketing
                                            information.</p>

                                        <p>**PLEASE NOTE: We can only accept entry &amp; orders from the United States
                                            and Puerto Rico.</p>

                                        <p>For more information on our price comparisons, and an explanation of some of
                                            the abbreviations used in this promotion, please visit <a
                                                    href="http://www.thepsh.com"
                                                    target="_blank">www.thepsh.com/prices</a>.</p>

                                        <p><a target="_blank" href="https://www.thepsh.com">Do Not Sell My
                                            Info</a><br>&nbsp;THEPSH's <a target="_blank"
                                                                       href="http://www.thepsh.com">Terms
                                            of Use</a>&nbsp;<a
                                                href="https://www.thepsh.com"
                                                target="_blank">Ad Preferences</a><br><a target="_blank"
                                                                                         href="https://www.thepsh.com">Accessibility
                                            Statement</a></p>
                                    </div>
                                </div>
                                <p>© <span id="eoCopyright">2022</span>&nbsp;Publishers Shopping House</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="targetedProducts" class="devices"></div>
        </div>
    </div>

</template>
<script>
    import store from '../../store';
    export default {
        data() {
            return {
                isHidden: false,
                addingToCart: false,
                shippingMethodName: null,
                placingOrder: false,
                checkoutLink: route('checkout.create'),
                csrfToken: ThePsh.csrfToken,
                first_name: ThePsh.user_first_name,
                isMobile: ThePsh.is_mobile
            }
        },
        mutations: {

        },
        computed: {
            cart() {
                return store.state.cart;
            },
        },
        mounted() {
        },

        methods: {

            getThumbnail(ProductImages) {
                let JsonImage = JSON.parse(ProductImages);
                console.log(JsonImage[0].src);
                return JsonImage[0].src;
            },
            image(img) {

                if (img !== undefined) {
                    let imagePath = window.location.origin + '/images/main/' + img;
                    return imagePath;
                }
            },

            remove(cartItem) {
                this.loadingOrderSummary = true;

                store.removeCartItem(cartItem);

                if (store.cartIsEmpty()) {

                }

                $.ajax({
                    method: 'DELETE',
                    url: route('cart.remove', { cartItemId: cartItem.id }),
                }).then((cart) => {
                    store.updateCart(cart);
                    /*this.cartObject = store.state.cart;*/
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                }).always(() => {
                    this.loadingOrderSummary = false;
                });
            },

            updateQuantity(cartItem, qty) {
                if (qty < 1 || this.exceedsMaxStock(cartItem, qty)) {
                    return;
                }

                if (isNaN(qty)) {
                    qty = 1;
                }

                this.loadingOrderSummary = true;

                cartItem.qty = qty;

                $.ajax({
                    method: 'PUT',
                    url: route('cart.update', { cartItemId: cartItem.id }),
                    data: { qty: qty || 1 },
                }).then((cart) => {
                    store.updateCart(cart);
                    /*this.cartObject = store.state.cart;*/
                }).catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                }).always(() => {
                    this.loadingOrderSummary = false;
                });
            },

            exceedsMaxStock(cartItem, qty) {
                return parseInt(cartItem.product.manage_stock) && parseInt(cartItem.product.stock_quantity) < qty;
            },

            submitOrder() {

                this.placingOrder = true;

                $.ajax({
                    method: 'POST',
                    url: route('checkout.create'),
                    data: this.form,
                }).then((response) => {
                    if (response.redirectUrl) {
                        window.location.href = response.redirectUrl;
                    } else if (this.form.payment_method === 'stripe') {
                        this.confirmStripePayment(response);
                    } else {
                        this.confirmOrder(response.orderId, this.form.payment_method);
                    }
                }).catch((xhr) => {
                    if (xhr.status === 422) {
                        this.errors.record(xhr.responseJSON.errors);
                    }

                    this.$notify(xhr.responseJSON.message);

                    this.placingOrder = false;
                });
            },

        }
    }
</script>

