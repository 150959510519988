<template>
    <div>
        <Level title="Level"></Level>
    </div>
</template>

<script>
    import Level from "./Level"
    export default {
        name: "HeaderLevel",
        components: {
            Level
        },
        data () {
            return {
                show: false,
            };
        }
    }
</script>

<style scoped>

</style>