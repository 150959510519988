<template>
    <div class="container" @click="isOpen = !isOpen">
        <a href="javascript:;">
            {{ title }}
        </a>
        <span class="uninav__my-status__icon uninav__token-level-icon uninav__token-level-icon--bronze"></span>
        <svg viewBox="0 0 1030 638" width="1">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
        </svg>

            <div class="sub-menuu" v-if="isOpen">

                        <div class="uninav__token-level-img uninav__token-level-img--lrg" > <span alt="" :class="changeClass" style="width: 38px;height: 38px;margin: 18px"></span></div>
                        <div class="uninav__token-level-feedback__text uninav__token-level-feedback__text--withspin" :style="{color:border}">

                            <p class="uninav__token-level-feedback__header">Way To Go! You've Leveled Up!
                            </p>
                            <p class="uninav__token-level-feedback__msg">
        <span class="uninav__token-level-feedback__msg__content">Spin The Silver LEVEL-UP WHEEL now, or in My Account, for your chance to WIN!
</span>
                                <a class="uninav__token-level-feedback__msg__learn" :href="route('level')">Learn More</a>
                            </p>

                        </div>

                        <div class="uninav__levelup">
                            <p class="uninav__levelup__header uninav-level-color">
                                Way To Go! You've Leveled Up!
                            </p>
                            <p class="uninav__levelup__msg">
                                Spin The SILVER LEVEL-UP WHEEL now, or in My Account, for your chance to WIN!
                            </p>
                        </div>

                        <ul class="uninav__level-chart">
                            <li class="row uninav__level-chart-item uninav__level-chart-item--bronze uninav__level-chart-item--past"   :style="{'border-color':computedBronze}"
                                data-level="bronze">
                                <span class="uninav__token-level-icon uninav__token-level-icon--bronze"></span>
                                <span class="uninav__token-level-label">BRONZE</span>
                                <span class="uninav__token-level-amount" aria-label="0 - 2,499 Tokens" v-show="bronze">0 - 2,499</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--silver uninav__level-chart-item--current"  :style="{'border-color':computedSilver}" data-level="silver">
                                <span class="uninav__token-level-icon uninav__token-level-icon--silver"></span>
                                <span class="uninav__token-level-label">SILVER</span>
                                <span class="uninav__token-level-amount" aria-label="2,500 - 99,999 Tokens " v-show="silver">2,500 - 99,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--gold" data-level="gold" :style="{'border-color':computedGold}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--gold"></span>
                                <span class="uninav__token-level-label">GOLD</span>
                                <span class="uninav__token-level-amount" aria-label="100,000 - 499,999 Tokens" v-show="gold">100,000 - 499,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--platinum" data-level="platinum"   :style="{'border-color':computedPlatinume}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--platinum"></span>
                                <span class="uninav__token-level-label">PLATINUM</span>
                                <span class="uninav__token-level-amount" aria-label="500,000 - 999,999 Tokens" v-show="platinum">500,000 - 999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--emerald" data-level="emerald" :style="{'border-color':computedEmerald}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--emerald"></span>
                                <span class="uninav__token-level-label">EMERALD</span>
                                <span class="uninav__token-level-amount" aria-label="1,000,000 - 2,499,999 Tokens" v-show="emeralad">1,000,000 - 2,499,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--amethyst" data-level="amethyst" :style="{'border-color':computedAmethyst}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--amethyst"></span>
                                <span class="uninav__token-level-label">AMETHYST</span>
                                <span class="uninav__token-level-amount" aria-label="2,500,000 - 4,999,999 Tokens" v-show="amethyst">2,500,000 - 4,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--sapphire" data-level="sapphire" :style="{'border-color':computedSapphire}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--sapphire"></span>
                                <span class="uninav__token-level-label">SAPPHIRE</span>
                                <span class="uninav__token-level-amount" aria-label="5,000,000 - 24,999,999 Tokens" v-show="sapphire">5,000,000 - 24,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--ruby" data-level="ruby" :style="{'border-color':computedRuby}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--ruby"></span>
                                <span class="uninav__token-level-label">RUBY</span>
                                <span class="uninav__token-level-amount" aria-label="25,000,000 - 49,999,999 Tokens" v-show="ruby">25,000,000 - 49,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--diamond" data-level="diamond" :style="{'border-color':computedDiamond}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--diamond"></span>
                                <span class="uninav__token-level-label">DIAMOND</span>
                                <span class="uninav__token-level-amount" aria-label="50,000,000 - 99,999,999 Tokens" v-show="diamond">50,000,000 - 99,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--yellow_diamond" data-level="yellow_diamond" :style="{'border-color':computedYellowDiamond}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--yellow_diamond"></span>
                                <span class="uninav__token-level-label">YELLOW DIAMOND</span>
                                <span class="uninav__token-level-amount" aria-label="100,000,000 - 199,999,999 Tokens" v-show="yellowDiamond">100,000,000 - 199,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--black_diamond" data-level="black_diamond" :style="{'border-color':computedBlackDiamond}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--black_diamond"></span>
                                <span class="uninav__token-level-label">BLACK DIAMOND</span>
                                <span class="uninav__token-level-amount" aria-label="200,000,000 - 349,999,999 Tokens" v-show="blackDiamond">200,000,000 - 349,999,999</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                            <li class="row uninav__level-chart-item uninav__level-chart-item--red_diamond" data-level="red_diamond" :style="{'border-color':computedRedDiamond}">
                                <span class="uninav__token-level-icon uninav__token-level-icon--red_diamond"></span>
                                <span class="uninav__token-level-label">RED DIAMOND</span>
                                <span class="uninav__token-level-amount" aria-label="350,000,000 + Tokens" v-show="redDiamond">350,000,000 +</span>
                                <span class="uninav__token-level-check"></span>
                            </li>
                        </ul>
                    </div>
                </div>
</template>

<script>
export default {
    name: 'Level',
    props: ['title', 'items'],
    data () {
        return {
            border:'',
            profil:'',
            isOpen: false,
            gold:false,
            silver:false,
            bronze:false,
            platinum:false,
            emeralad:false,
            amethyst:false,
            sapphire:false,
            ruby:false,
            diamond:false,
            yellowDiamond:false,
            blackDiamond:false,
            redDiamond:false,
            isPromoted: "{background-color: red ;}",
            tokens_quota: window.ThePsh.tokens_quota,
            class:'',
        }
    },
    computed: {
        computedBronze: function () {
            if(this.tokens_quota>=0 && this.tokens_quota<=2499){
                this.bronze=true;
                this.class='uninav__token-level-icon--bronze uninav__token-level-icon';
                 return this.border='#d68249';
            }},
        computedSilver: function () {
          if(this.tokens_quota>=2500 && this.tokens_quota<99999){
              this.class='uninav__token-level-icon--silver uninav__token-level-icon';
              this.silver=true;
                return this.border='#afaeae';
            }},
        computedGold: function () {
            if(this.tokens_quota>=100000 && this.tokens_quota<499999){
                this.class='uninav__token-level-icon--gold uninav__token-level-icon';
                this.gold=true;
                return this.border='#ceab49';
            }},
        computedPlatinume: function () {
            if(this.tokens_quota>=500000 && this.tokens_quota<999999){
                this.class='uninav__token-level-icon--platinume uninav__token-level-icon';
                return this.border='#afaeae';
            }},
        computedEmerald: function () {
           if(this.tokens_quota>=1000000 && this.tokens_quota<2499999){
               this.class='uninav__token-level-icon--emerald uninav__token-level-icon';
               this.emeralad=true;
                return this.border='#53a564';
            }},
        computedAmethyst: function () {
              if(this.tokens_quota>=2500000 && this.tokens_quota<4999999){
                  this.class='uninav__token-level-icon--amethyst uninav__token-level-icon';
                  this.amethyst=true;
                return this.border='#c36cd8';
            }},
        computedSapphire: function () {
            if(this.tokens_quota>=5000000 && this.tokens_quota<24999999){
                this.class='uninav__token-level-icon--sapphire uninav__token-level-icon';
                this.sapphire=true;
                return this.border='#6195f2';
            }},
        computedRuby: function () {
                if(this.tokens_quota>=25000000 && this.tokens_quota<49999999){
                this.class='uninav__token-level-icon--ruby uninav__token-level-icon';
                this.ruby=true;
                return this.border='#f46767';
            }},
        computedDiamond: function () {
            if(this.tokens_quota>=50000000 && this.tokens_quota<99999999){
                this.class='uninav__token-level-icon--diamond uninav__token-level-icon';
                this.diamond=true;
                return this.border='#91acc9';
            }},
        computedYellowDiamond: function () {
            if(this.tokens_quota>=100000000 && this.tokens_quota<199999999){
                this.class='uninav__token-level-icon--yellow_diamond uninav__token-level-icon';
                this.diamond=true;
                return this.border='#c9bc1c';
            }},
        computedBlackDiamond: function () {
            if(this.tokens_quota>=200000000 && this.tokens_quota<349999999){
                this.class='uninav__token-level-icon--black_diamond uninav__token-level-icon';
                this.blackDiamond=true;
                return this.border='#787777';
            }},
        computedRedDiamond: function () {
            if(this.tokens_quota>=350000000){
                this.class='uninav__token-level-icon--red_diamond uninav__token-level-icon';
                this.redDiamond=true;
                return this.border='#c64242';
            }},
        changeClass: function () {
                return this.class;
            },

    },

}
</script>

<style>
.sub-menuu {
    position: absolute;
    background-color: #222;
    top: calc(100% + 12px);
    transform: translateX(-92%);
    border-radius: 0px 0px 16px 16px;
}
@media screen and (max-width: 923px) {
    .su {
        width: 752px;
    }
}


</style>
