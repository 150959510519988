<template>
    <div class="content">
        <div class="mainbar">
            <div class="mainbar__content">
                <div class="mainbar__body">
                    <div>
                        <div class="token-bank">
                            <div class="token-bank__img coin-icon"></div>
                            <div class="token-bank__title"><span>Token</span> <span>Balance</span></div>
                            <div class="token-bank__balance">
                                <span><balance-token></balance-token></span>
                            </div>
                        </div>
                    </div>
                    <div id="vue__control-bar">
                        <span>
                            <div class="control-bar control-bar--desktop"></div>
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="main main--desktop">
            <div class="row">
                <div class="col-md-9">
                    <div class="main__body main__body--desktop">
                        <div id="vue__category-bar">
                            <ul class="list-group">
                                <li class="list-group-item"
                                    v-for="category in categories" :key="category.id">
                                    <a :href="route('category_prize',category.slug)"
                                       class="category-controls__item__anchor"
                                        v-text="category.name"></a>
                                    <div class="category-controls__item__anchor__underline"></div>
                                </li>
                            </ul>
                        </div>
                        <div class="prize-detail">
                            <div class="prize-detail-wrapper prize-detail-wrapper--desktop">
                                <section class="content top-bar">
                                    <div class="giveaway-legal">
                                        Gwy.# 19704 |
                                        <a :href="route('pages.show','official-rules')" target="_blank" class="text--gray">Official Rules</a> |
                                        <a :href="route('pages.show','sweepstakes-facts')" target="_blank" class="text--gray">Sweeps Facts</a>
                                    </div>
                                    <!--<div class="item__favorite"  >
                                        <svg viewBox="0 0 32 29.6" class="item__favorite__heart item__favorite__heart&#45;&#45;inactive">
                                            <path d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"></path>
                                        </svg>
                                    </div>-->
                                </section>
                                <section class="content main-body">
                                    <aside class="prize-detail-aside">
                                        <div class="meta"  >
                                            <div class="data"  >
                                                <div class="data__label text--gray"  >
                                                    Award Date:
                                                </div>
                                                <div class="text--orange text--uppercase"> {{prize.start_date | formatDate("dddd, MMMM Do, YYYY")}} </div>
                                            </div>
                                        </div>
                                        <div class="meta prize-img-wrapper">
                                            <div class="prize-img-box prize-img-box--desktop">
                                                <img alt="$100 Walmart Gift Card" class="prize-img lazy-images"  :src="image(prize.thumbnail)">
                                            </div>
                                            <!--<div class="card-carousel-wrapper&#45;&#45;hidden card-carousel-wrapper&#45;&#45;full">
                                                <div disabled="disabled" class="card-carousel-control">
                                                    <div disabled="disabled" class="card-carousel&#45;&#45;nav__left"></div>
                                                </div>
                                                <div class="card-carousel">
                                                    <div class="card-carousel&#45;&#45;overflow-container">
                                                        <div class="card-carousel-cards" style="transform: translateX(0px);">
                                                            <div class="card-carousel&#45;&#45;card card-carousel&#45;&#45;card&#45;&#45;active">
                                                                <img src="/images/main/superprizes/$100Walmart_640x480_1.jpg">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div disabled="disabled" class="card-carousel-control"  >
                                                    <div disabled="disabled" class="card-carousel&#45;&#45;nav__right"></div>
                                                </div>
                                            </div>-->
                                        </div>
                                    </aside>
                                    <main class="meta">
                                        <h1 class="title text--gray"> {{prize.title}}</h1>
                                        <p class="description text--gray"> {{prize.description}} </p>
                                        <section class="row">
                                            <div class="col-md-8 meta">
                                                <div class="data"><span class="data__label text--gray">Prize Value:</span>
                                                    <span class="text--orange">${{prize.prize_value}}</span>
                                                </div>
                                                <div class="data">
                                                    <span class="data__label text--gray">Tokens:</span>
                                                    <span class="text--orange text--heavy">
                                                        {{prize.tokens_per_entry}}
                                                        <span class="text--sm text--gray text--italic">PER ENTRY</span>
                                                    </span>
                                                </div>
                                                <div class="data data--prize-unlock-level text--gray">
                                                    <span>
                                                        <img alt="UNLOCKS AT BRONZE LEVEL" src="/images/main/levels/bronze@2x.png" class="data--prize-unlock-level__gem">
                                                    </span>
                                                    <span>
                                                        <div>UNLOCKS AT</div>
                                                        <div>{{ prize.unlock_level}} LEVEL</div>
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="col-md-4 prize-detail-countdown-timer">
                                                <div class="countdown-timer-box text--green loaded" >
                                                    <div class="countdown-timer-title">DRAWING ENDS IN</div>
                                                    <div>
                                                        <div class="countdown-timer">
                                                            <div class="countdown-timer__hrs">
                                                                <div class="countdown-timer__time">{{prize.days}}</div>
                                                            </div>
                                                            <div class="countdown-timer__sep">:</div>
                                                            <div class="countdown-timer__hrs">
                                                                <div class="countdown-timer__time">{{prize.hours}}</div>
                                                            </div>
                                                            <div class="countdown-timer__sep">:</div>
                                                            <div class="countdown-timer__mins">
                                                                <div class="countdown-timer__time">{{prize.minutes}}</div>
                                                            </div>
                                                            <div class="countdown-timer__sep">:</div>
                                                            <div class="countdown-timer__secs">
                                                                <div class="countdown-timer__time">{{prize.seconds}}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section>
                                        <div class="entry-button" v-if="token_balance >= prize.tokens_per_entry">
                                            <div class="prize-entry" >
                                                <form :action="route('rewards.exchange')" novalidate="novalidate" autocomplete="off" method="post">
                                                    <input type="hidden" name="prize_id" :value="prize.slug"/>
                                                    <input type="hidden" name="_token" :value="csrfToken"/>
                                                    <div class="prize-entry-ticket__form__errors" :class="{'prize-entry-ticket__form__errors--show': upToMaxToken}">
                                                        <div tabindex="-1" class="prize-entry-ticket__form__errors__close-btn" @click="closeToolTip">x</div>
                                                        <ul id="prize-entry-form-errors" tabindex="-1" class="prize-entry-ticket__form__errors__list">
                                                            <li class="prize-entry-ticket__form__errors__list__item">
                                                                Sorry, you do not have enough tokens for {{entries}} entries.
                                                                <br><strong>You can get up to
                                                                <span class="prize-detail-entry-max" @click="easyEntries(getMaxEntries())">{{this.getMaxEntries()}}</span>
                                                                entries for this prize.</strong>
                                                            </li>
                                                        </ul>
                                                        <div class="prize-entry-ticket__form__errors__arrow-down"></div>
                                                    </div>
                                                    <div class="prize-entry-ticket__helper-msg">
                                                        <div>
                                                            How Many Entries Would You Like?
                                                        </div>
                                                        <div class="prize-entry-ticket__helper-msg__arrow-down"></div>
                                                    </div>
                                                    <div class="prize-entry__ticket-wrapper">
                                                        <div class="prize-entry-ticket prize-entry-ticket--raffle prize-entry-sprite prize-entry-sprite--raffle">
                                                            <input v-model="entries" name="entries"
                                                                   aria-describedby="prize-entry-form-errors"  maxlength="4"
                                                                   placeholder="9999"  type="tel"
                                                                   class="prize-entry-ticket__fee-input" >
                                                        </div>
                                                        <div class="prize-entry-ticket-calc" :class="{ 'prize-entry-ticket-calc--show': entries }" v-if="entries">
                                                             <span class="prize-entry-ticket-calc__fee text--gray">
                                                                <div class="count-up" >{{ entries*prize.tokens_per_entry }}</div>
                                                             </span>
                                                             <span class="prize-entry-ticket-calc__fee-key text--gray">TOKENS</span>
                                                        </div>
                                                    </div>
                                                    <div class="prize-entry__easy-entry">
                                                        <div class="prize-entry__easy-entry__title">
                                                            <div class="prize-entry__easy-entry__title-value">EASY ENTRIES!</div>
                                                        </div>
                                                        <ul class="prize-entry__easy-entry__list">
                                                            <li class="prize-entry__easy-entry__list__item text--heavy" :class="{'prize-entry__easy-entry__list__item--disabled': !enableEntry(10)}" @click="easyEntries(10)">10</li>
                                                            <li class="prize-entry__easy-entry__list__item text--heavy" :class="{'prize-entry__easy-entry__list__item--disabled': !enableEntry(25)}" @click="easyEntries(25)">25</li>
                                                            <li class="prize-entry__easy-entry__list__item text--heavy" :class="{'prize-entry__easy-entry__list__item--disabled': !enableEntry(50)}" @click="easyEntries(50)">50</li>
                                                            <li class="prize-entry__easy-entry__list__item text--heavy" :class="{'prize-entry__easy-entry__list__item--disabled': !enableEntry(100)}" @click="easyEntries(100)">100</li>
                                                        </ul>
                                                    </div>
                                                    <div class="prize-entry__enter-now">
                                                        <button  class="prize-entry__enter-now__btn"
                                                                 type="submit"
                                                                 :class="{ 'prize-entry__enter-now__btn--enabled' : showEntriesBtn, 'prize-entry__enter-now__btn--disabled' : !showEntriesBtn}"
                                                                 :disabled="!showEntriesBtn">ENTER NOW!</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div class="entry-button" v-else >
                                            <div class="prize-detail-entries-box mt-4 text--gray" >
                                                Sorry, you do not have enough <br> tokens to enter for this prize.
                                                <div class="btn-more-games mt-4">
                                                    <a :href="route('games.index')" class="btn btn-orange">Play More games to win new tokens</a>
                                                </div>
                                            </div>
                                        </div>
                                    </main>
                                </section>
                                <div class="prize-entry-winners-section">
                                    <div class="prize-entry-winners-section__head">
                                        <img alt="Previous Winners Logo" src="/images/main/ribbon.png"
                                             class="prize-entry-winners-section__head ribbon-img">
                                        <h5 class="prize-entry-winners-section__head heading"> PREVIOUS WINNERS </h5>
                                    </div>
                                    <ul class="prize-entry-winners-section__winners">
                                        <li class="prize-entry-winners-section__winners__item-desktop" v-for="(winner, index) in prizewinners"  :key="index">
                                            {{winner.name}}.
                                            <span class="prize-entry-winners-section__winners date">{{winner.winnerDate}}</span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="prize-detail-wrapper__dimmer"  :class="{'prize-detail-wrapper__dimmer--show': upToMaxToken}"></div>
                            </div>
                        </div>

                    </div>
                </div>
                <div class="col-md-3">
                    <div class="main__sidebar">
                        <div id="mystery-giftbox-wrapper" class="mystery-giftbox">
                            <div id="desktop-mystery-gift-box-wrapper" class="mystery-box-wrapper-locked ">
                                <div class="mystery-gift-info-button" tabindex="0">i</div>
                                <img class="mystery-gift-logo" alt="Mystery Gift Logo" src="/images/main/mystery-gift-logo.png">
                                <div class="mystery-gift-info-confirm"  >
                                    <div class="mystery-gift-info-content">Enter any giveaway in the PCHrewards Token Exchange between 12 AM and 11:59 PM, ET to unlock the Daily Mystery Gift.  Once unlocked, you must come back to claim your Daily Mystery Gift the following day between 12 AM and 11:59 PM, ET or it will be forfeited.</div>
                                    <a class="mystery-info-confirm-button" href="#" tabindex="-1">OK</a>
                                </div>
                                <div class="mystery-gift-box-animated-bak">
                                    <img src="/images/main/mystery-rays-2.png" alt="Mystery Gift Animation">
                                </div>

                                <div class="mystery-gif-box-frame mystery-box-icon-locked">
                                    <div id="mystery-box-icon-container"  >
                                        <img class="mystery-gift-box-icon" alt="Mystery Gift Locked Status" src="/images/main/giftbox-icon-locked.png">
                                    </div>
                                    <div class="mystery-gift-box-claim-msg">
                                        <span>LOCKED</span><br>Enter Any Giveaway To Unlock Tomorrow's Mystery Gift!
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="rewards_right"  ></div>
                        <div class="rev-wrap"  >
                            <div class="rev 300x600 ad_slot_header" id="div-pch-monetization-multiple"  style="min-height: 270px; margin-left: auto; margin-right: auto; min-width: 160px; display: none;" data-google-query-id="CPSh0NS22vkCFUEvPwodmz0HrQ">
                                <div id="google_ads_iframe_/5499/pch.pch/tokenexchange_product_0__container__" style="border: 0pt none; width: 300px; height: 0px;"  ></div>
                            </div>
                        </div>
                        <div class="rev-wrap"  ></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: ['categories','prize','prizewinners'],
    data() {
        return {
            token_balance: window.ThePsh.token_balance,
            entries: null,
            showEntriesBtn: false,
            isDisabled: true,
            csrfToken: window.ThePsh.csrfToken,
            upToMaxToken: false,

        }
    },
    mounted() {
        if(this.entries){
            this.isDisabled = false;
        }
    },
    watch : {
        entries() {
            this.upToMaxToken = (this.prize.tokens_per_entry *this.entries) > this.token_balance;
            this.showEntriesBtn = (!this.upToMaxToken && this.entries);
        }
    },
    methods :{
        image(img) {
            var currentLocation = window.location.origin;
            return currentLocation+'/images/main/superprizes/'+img ;
        },
        easyEntries(value) {
            this.entries = value;
        },
        enableEntry(value) {
            return (this.prize.tokens_per_entry * value) <= this.token_balance;
        },
        closeToolTip() {
            this.entries = null;
            this.upToMaxToken = false;
        },
        getMaxEntries() {
            return Math.floor(this.token_balance / this.prize.tokens_per_entry);

        }
    }
}

</script>
