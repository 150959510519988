<template>
    <span v-text="cart.quantity"></span>
</template>

<script>
    import CartHelpersMixin from "@/components/mixins/CartHelpersMixin";
    export default {
        mixins: [
            CartHelpersMixin,
        ],

        data() {
            return {

            }
        }
    }
</script>

<style scoped>

</style>