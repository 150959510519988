/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
import store from './store';

import CookieBar from './components/layout/CookieBar';
import RegisterComponent from './components/home/register/RegisterComponent.vue';
import LoginComponent from  './components/home/register/LoginComponent.vue';
import Logout from './components/home/register/Logout.vue';
import SetPasswordComponent from './components/home/register/SetPasswordComponent.vue';
import TokenListener from "./components/games/TokenListener";
import TokenListenerComponent from './components/games/TokenListenerComponent';
import NewEmailComponent from './components/account/NewEmailComponent';
import InfoComponent from "./components/account/InfoComponent";
import ProductsList from "./components/home/ProductsList.vue";
import Cart from "./components/home/Cart.vue";
import NavBar from "./components/layout/NavBar.vue";
//import NavLevel from "./components/layout/NavLevel.vue";
import HeaderLevel from "./components/layout/HeaderLevel.vue"
import DescriptionLevel from "./components/layout/DescriptionLevel.vue";
import BalanceToken from "./components/redeem/BalanceToken.vue";
import Rewards from "./components/redeem/Rewards.vue";
import Prize from "./components/redeem/Prize.vue";
import CheckoutCreate from './components/checkout/Create.vue';
import CartNotification from './components/checkout/CartNotification'

Vue.component('Prize',Prize);
Vue.component('Rewards',Rewards);
Vue.component('BalanceToken',BalanceToken);
Vue.component('descriptionLevel',DescriptionLevel);
//Vue.component('NavLevel',NavLevel);
Vue.component('HeaderLevel',HeaderLevel);
Vue.component('NavBar',NavBar);
Vue.component('cart-dropdown',Cart);

Vue.component('cookie-bar', CookieBar);
Vue.component('token-listener-js', TokenListener);
Vue.component('token-listener', TokenListenerComponent);
Vue.component('register-component',RegisterComponent);
Vue.component('login-component',LoginComponent);
Vue.component('logout',Logout);
Vue.component('set-password',SetPasswordComponent);
Vue.component('info-component',InfoComponent);
Vue.component('newemail-component',NewEmailComponent);
Vue.component('products-list',ProductsList);
Vue.component('checkout-create',CheckoutCreate);
Vue.component('cart-notification',CartNotification);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

new Vue({
    el: '#app',

    /*store: new Vuex.Store(store),*/

    computed: {
        cart() {
            return store.state.cart;
        },
    },
});

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': ThePsh.csrfToken,
    },
});
/*const routes = [
    {
        path: '/cart',
        name: 'cart',
        component: Cart
    }
];*/
