<template>
    <div>
        <div class="maincontent__body" >
            <section class="rewardspage">
                <header class="rewardspage__header">
                    <h1 class="rewardspage__header__mast" >Programme de fidélisation PSHrewards</h1>
                    <h2 class="rewardspage__header__title" >Montez de niveau !</h2>
                    <div class="rewardspage__header__texts" >
                        <p class="rewardspage__header__user" >Hi Malika,</p>
                        <p class="rewardspage__header__text" >See what leveling Up Can do for you !</p>
                    </div>
                </header>

                <div class="rewardspage__carousel rewardspage__carousel--gold" >
                    <ol class="rewardspage__carousel__tabs">
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--bronze">
                            Bronze
                            <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--bronze" >0 - 2,499 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--silver"> Silver <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--silver" >2,500 - 99,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--gold"> Gold <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--gold" >100,000 - 499,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--platinum">Platinum <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--platinum" >500,000 - 999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--emerald">Emerald <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--emerald" >1,000,000 - 2,499,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--amethyst"> Amethyst <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--amethyst" >2,500,000 - 4,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--sapphire"> Sapphire <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--sapphire" _msthash="1837212" _msttexthash="399438" _msthidden="1">5,000,000 - 24,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--ruby"> Ruby <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--ruby" _msthash="1837433" _msttexthash="424853" _msthidden="1">25,000,000 - 49,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--diamond"> Diamond <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--diamond" >50,000,000 - 99,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--yellow_diamond"> Yellow diamond <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--yellow_diamond" >100,000,000 - 199,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--black_diamond"> Black diamond<span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--black_diamond" >200,000,000 - 349,999,999 TOKENS
</span>
                        </li>
                        <li class="rewardspage__carousel__tab rewardspage__carousel__tab--red_diamond"> Red diamond <span class="rewardspage__carousel__tab__tokenrange rewardspage__carousel__tab__tokenrange--red_diamond" >350,000,000 + TOKENS
</span>
                        </li>
                    </ol>
                    <div class="rewardspage__carousel__nav" bis_skin_checked="1">
                        <ul>
                            <li v-for="(level, i) in levelsFilter(week.id)" :key="i">
                                <p>{{ i }}-{{ level.length }}</p>
                                <ul v-if="level.length">
                                    <li v-for="empl in level" :key="empl.id">
                                        <div class="employee">
                                            <h2 class="rewardspage__carousel__title"  :style="{'color':empl.color}">{{ empl.name }}</h2>
                                            <p class="rewardspage__carousel__tokens" >{{ empl.dept }}</p>
                                        </div>
                                        <p class="rewardspage__carousel__text">{{empl.text}}</p>
                                    </li>

                                </ul>
                            </li>
                        </ul>
                        <span role="presentation" aria-hidden="true" class="rewardspage__carousel__btn rewardspage__carousel__prev" title="View Previous Level" @click="selectlevel(-1)"></span>
                        <span role="presentation" aria-hidden="true" class="rewardspage__carousel__btn rewardspage__carousel__next" title="View Next Level" @click="selectlevel(1)" ></span>

                    </div>
                    <ol class="rewardspage__carousel__levels" style="touch-action: none; user-select: none; -webkit-user-drag: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);">
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--bronze rewardspage__carousel__level--index1 " data-level="bronze">
                            <span class="rewardspage__carousel__levelname">Bronze</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--bronze"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--silver rewardspage__carousel__level--index2 " data-level="silver">
                            <span class="rewardspage__carousel__levelname">Silver</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--silver"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--gold rewardspage__carousel__level--index3 " data-level="gold">
                            <span class="rewardspage__carousel__levelname">Gold</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--gold"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--platinum rewardspage__carousel__level--index11 rewardspage__carousel__level--higher" data-level="platinum">
                            <span class="rewardspage__carousel__levelname">Platinum</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--platinum"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--emerald rewardspage__carousel__level--index10 rewardspage__carousel__level--higher" data-level="emerald">
                            <span class="rewardspage__carousel__levelname">Emerald</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--emerald"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--amethyst rewardspage__carousel__level--index9 rewardspage__carousel__level--higher" data-level="amethyst">
                            <span class="rewardspage__carousel__levelname">Amethyst</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--amethyst"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--sapphire rewardspage__carousel__level--index8 rewardspage__carousel__level--higher" data-level="sapphire">
                            <span class="rewardspage__carousel__levelname">Sapphire</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--sapphire"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--ruby rewardspage__carousel__level--index7 rewardspage__carousel__level--higher" data-level="ruby">
                            <span class="rewardspage__carousel__levelname">Ruby</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--ruby"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--diamond rewardspage__carousel__level--index6 rewardspage__carousel__level--higher" data-level="diamond">
                            <span class="rewardspage__carousel__levelname">Diamond</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--diamond"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--yellow_diamond rewardspage__carousel__level--index5 rewardspage__carousel__level--higher" data-level="yellow_diamond">
                            <span class="rewardspage__carousel__levelname">Yellow Diamond</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--yellow_diamond"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--black_diamond rewardspage__carousel__level--index4 rewardspage__carousel__level--higher" data-level="black_diamond">
                            <span class="rewardspage__carousel__levelname">Black Diamond</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--black_diamond"></span>
                        </li>
                        <li class="rewardspage__carousel__level rewardspage__carousel__level--red_diamond rewardspage__carousel__level--index3 rewardspage__carousel__level--higher" data-level="red_diamond">
                            <span class="rewardspage__carousel__levelname">Red Diamond</span>
                            <span class="rewardspage__carousel__levelicon rewardspage__carousel__levelicon--red_diamond"></span>
                        </li>
                    </ol>
                </div>

                <div class="rewardspage__tiles">
                    <div class="rewardspage__tile rewardspage__tile--welcome">
                        <div class="rewardspage__tilebody">
                            <h3 class="rewardspage__tile__header">Welcome Tokens</h3>
                            <div class="rewardspage__tile__upperlevelcontent">
                                <h4 class="rewardspage__tile__title">Instant Rewards Just For YOU!</h4>
                                <p class="rewardspage__tile__text">The<strong data-leveldata="name" _istranslated="1"> Gold</strong> Token Reward increase starts immediately once this level is reached and can be claimed daily by visiting any of our PSH sites. </p>
                                <p class="rewardspage__welcometokens">
                                    <strong class="rewardspage__welcometokens__amount">500</strong>
                                    <span class="rewardspage__welcometokens__tokens">TOKENS</span>
                                    <span class="rewardspage__welcometokens__day">EVERY DAY!</span>
                                </p>
                            </div>
                            <div class="rewardspage__tile__introlevelcontent">
                                <h4 class="rewardspage__tile__title">Instant Rewards You'll Love!</h4>
                                <p class="rewardspage__tile__text">
                                    Daily Token Rewards start at the <strong>Silver</strong> level
                                    and can be claimed by visiting any of our PSH sites.
                                </p>
                                <p class="rewardspage__welcometokens" _msthidden="3">
                                    <strong class="rewardspage__welcometokens__amount" >EARN</strong>
                                    <span class="rewardspage__welcometokens__tokens">TOKENS</span>
                                    <span class="rewardspage__welcometokens__day">EVERY DAY!</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="rewardspage__tile rewardspage__tile--wheel">
                        <div class="rewardspage__tilebody">
                            <h3 class="rewardspage__tile__header">Roue de niveau supérieur</h3>
                            <div class="rewardspage__tile__upperlevelcontent">
                                <h4 class="rewardspage__tile__title">Over <span data-leveldata="wheel_prize_min_count" _istranslated="1">300</span> Instant Win Prizes!</h4>
                                <p class="rewardspage__tile__text"> The Exclusive <strong data-leveldata="name" _mstmutation="1" _istranslated="1">Gold</strong> Thank You Wheel is packed with prizes that only GOLD players can win! Spin as you “level up” or on the “My Account” page.
                                </p>
                            </div>
                            <div class="rewardspage__tile__introlevelcontent" bis_skin_checked="1" _msthidden="2">
                                <h4 class="rewardspage__tile__title" >Big Instant Win Prizes!</h4>
                                <p class="rewardspage__tile__text" >
                                    Starting at <strong >Silver</strong>, every level gained will allow players to spin an
                                    Exclusive Thank You Wheel that's packed with prizes!
                                    <em>Spin as you “level up” or on the “My Account” page.</em>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="rewardspage__tile rewardspage__tile--leader" >
                        <div class="rewardspage__tilebody">
                            <h3 class="rewardspage__tile__header" >Récompense Token Leader</h3>
                            <h4 class="rewardspage__tile__title" >Token Leader Reward Cash Winners Are Guaranteed Daily!</h4>
                            <div class="rewardspage__tile__upperlevelcontent">
                                <p class="rewardspage__tile__text" > Token Leader Reward
                                    Cash Winners Are Guaranteed Daily! <strong data-leveldata="name" _istranslated="1">Gold</strong>PLAYERS! </p>
                                <p class="rewardspage__dailycash">
                                    <span class="rewardspage__dailycash__go" >Go For</span>
                                    <span class="rewardspage__dailycash__amount">
                                <span data-leveldata="token_leaderboard_prize_amt" >150</span> Daily </span>
                                </p>
                            </div>
                            <div class="rewardspage__tile__introlevelcontent" >
                                <p class="rewardspage__tile__text">
                                    <strong _mstmutation="1">Bronze</strong> players who level-up to <strong >Silver</strong>
                                    are able to play for a chance to win our PSH.com Daily Token Leaderboard Cash Prize!
                                </p>
                                <p class="rewardspage__dailycash">
                                    <span class="rewardspage__dailycash__go">Go For</span>
                                    <span class="rewardspage__dailycash__amount">BIG CASH</span>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="rewardspage__tile rewardspage__tile--exchange">
                        <div class="rewardspage__tilebody">
                            <h3 class="rewardspage__tile__header">Prix d’échange de jetons</h3>
                            <h4 class="rewardspage__tile__title">
                                You Could Win TONIGHT!</h4>
                            <div class="rewardspage__tile__upperlevelcontent">
                                <p class="rewardspage__tile__text"> With OVER 40 GUARANTEED PRIZES and WINNERS EVERY DAY, the Tokens you've earned could really pay off! Plus, don't miss out on the GOLD LEVEL exclusive bonus prize!  <a class="rewardspage__exchange__enter" href="//rewards.pch.com" onclick="PCHGA.trackEvent('levelup/desktop','drawing','levelup_drawing');" >Enter Tonight's Drawing!</a>
                                </p>
                            </div>
                            <div class="rewardspage__tile__introlevelcontent">
                                <p class="rewardspage__tile__text" _msthidden="2">
                                    With OVER 40 GUARANTEED PRIZES and WINNERS EVERY DAY, the Tokens you've earned could really pay off!
                                    <a class="rewardspage__exchange__enter" href="//rewards.pch.com" onclick="PCHGA.trackEvent('levelup/desktop','drawing','levelup_drawing');">Enter Tonight's Drawing!</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <footer class="rewardspage__footer">
                    <p>Remember, your PSHRewards Status is based upon your All-Time Token Total, which never, ever decreases</p>
                    <p>(no matter how many Tokens you use at our PSHRewards Token Exchange).</p>
                </footer>

            </section>

            <section class="rewardsgrid">
                <header class="rewardsgrid__header">
                    <h1 class="rewardsgrid__title">Take Your PSH Experience To The Next LEVEL!</h1>
                    <p>It pays to play every day because the more you play, <strong>THE MORE YOU CAN WIN!</strong> You'll unlock exclusive benefits every time you reach a new level -- so keep on earning Tokens and enjoy the rewards! </p>
                </header>

                <div class="rewardsgrid__grid">
                    <div class="rewardsgrid__row rewardsgrid__row--header" role="row" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="columnheader">Level & <br _istranslated="1">TOKEN RANGE</div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="columnheader">PSH.COM DAILY LEADERBOARD</div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="columnheader">** THANK YOU WHEEL</div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="columnheader" >* WELCOME TOKENS</div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="columnheader">TOKEN EXCHANGE
                            PRIZES</div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--red_diamond" role="row" aria-rowindex="2" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname" >RED DIAMOND</span>
                            <span class="rewardsgrid__tokenrange">350,000,000 + TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $550.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell" >
                            <span class="rewardsgrid__cell__text" title="** Thank You Wheel"> Over 1500 <br _istranslated="1">PRIZES </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            30,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell" >
                            <span class="rewardsgrid__cell__text" title="Token Exchange Prizes"> UNLOCK RED DIAMOND PRIZE OPPORTUNITY <br _istranslated="1"> </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--black_diamond" role="row" aria-rowindex="3">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Black Diamond</span>
                            <span class="rewardsgrid__tokenrange" >200,000,000 - 349,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $500.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 1200 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            25,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Black Diamond <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--yellow_diamond" role="row" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname" >Yellow Diamond</span>
                            <span class="rewardsgrid__tokenrange">100,000,000 - 199,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell" bis_skin_checked="1">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard" _msthash="1979159" _msttexthash="43303">
                                                            $450.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 1000 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            20,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Yellow Diamond <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--diamond" role="row">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Diamond</span>
                            <span class="rewardsgrid__tokenrange">50,000,000 - 99,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $400.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 900 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            15,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Diamond <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--ruby" role="row" aria-rowindex="6">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Ruby</span>
                            <span class="rewardsgrid__tokenrange">25,000,000 - 49,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $375.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 800 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            12,500
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Ruby <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--sapphire" role="row">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Sapphire</span>
                            <span class="rewardsgrid__tokenrange">5,000,000 - 24,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $350.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 700 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            10,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Sapphire <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--amethyst" role="row" aria-rowindex="8" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Amethyst</span>
                            <span class="rewardsgrid__tokenrange" >2,500,000 - 4,999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $325.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 600 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            7,500
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Amethyst <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--emerald" role="row" aria-rowindex="9">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Emerald</span>
                            <span class="rewardsgrid__tokenrange" _msthash="2482961" >1,000,000 - 2,499,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell" >
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard" >
                                                            $300.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell" >
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel" >
                                                            OVER 500 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            5,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes" >
                            UNLOCK Emerald <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--platinum" role="row" aria-rowindex="10" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell">
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname">Platinum</span>
                            <span class="rewardsgrid__tokenrange" >500,000 - 999,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $250.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 400 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell" >
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            1,000
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Platinum <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--gold rewardsgrid__row--current rewardsgrid__row--expanded" role="row" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                            <span class="rewardsgrid__currenttext" >Your Level</span>
                                                        <span class="rewardsgrid__levelname">Gold</span>
                            <span class="rewardsgrid__tokenrange">100,000 - 499,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            $150.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell" >
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel">
                                                            OVER 300 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            500
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Gold <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--silver" role="row" aria-rowindex="12">
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname" >Silver</span>
                            <span class="rewardsgrid__tokenrange" >2,500 - 99,999 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard" role="cell" bis_skin_checked="1">
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard" _msthash="2034825" _msttexthash="42406">
                                                            $100.00
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel" role="cell">
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel" >
                                                            OVER 200 <br>PRIZES
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens">
                                                            250
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell">
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Silver <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                    <div class="rewardsgrid__row rewardsgrid__row--bronze" role="row" aria-rowindex="13" >
                        <div class="rewardsgrid__cell rewardsgrid__cell--level" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Level &amp; Token Range">
                                                        <span class="rewardsgrid__levelname" >Bronze</span>
                            <span class="rewardsgrid__tokenrange">0 - 2,499 TOKENS
</span>
                            <span role="presentation" aria-hidden="true" class="rewardsgrid__expando">+</span>
                        </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--leaderboard rewardsgrid__cell--noval" role="cell" >
                        <span class="rewardsgrid__cell__text" title="PCH.com Daily Leaderboard">
                                                            —
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--wheel rewardsgrid__cell--noval" role="cell" >
                        <span class="rewardsgrid__cell__text" title="** Thank You Wheel" _msthash="2035384" >
                                                            —
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--welcome rewardsgrid__cell--noval" role="cell">
                        <span class="rewardsgrid__cell__text" title="* Welcome Tokens" >
                                                            —
                                                    </span>
                        </div>
                        <div class="rewardsgrid__cell rewardsgrid__cell--exchange" role="cell" >
                        <span class="rewardsgrid__cell__text" title="Token Exchange Prizes">
                            UNLOCK Bronze <br>PRIZE OPPORTUNITY
                        </span>
                        </div>
                    </div>
                </div>

                <footer class="rewardsgrid__footer">
                    <p>* Given once per day for each site.
                        These tokens will be added to your All-Time Token balance and will not count toward the sites' Daily Token Leader Reward.
                    </p>
                    <p>** Tokens earned through PSHRewards “Thank You” Wheel Spin(s) will be added to your All-Time Token balance and
                        will not count toward the PSH.com Daily Token Leader Reward.
                    </p>
                </footer>
            </section>
        </div></div>
</template>
<script>
export default {
    name: 'descriptionLevel',
    data() {
        return {
            articles: null,
            table_counter: 0,
            text:'',
            livescore: null,
            class:'rewardspage__carousel__level--current',
            levels:
                [
                    {
                        "1": [{"id": "36", "name": "BRONZE", "dept": "0 - 2,499 TOKENS","color":"#d68249","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--bronze","text":"Our Introductory Level gets you started toward amazing benefits!"},]
                    },
                    {
                        "2": [{"id": "36", "name": "SILVER", "dept": "2,500 - 99,999 TOKENS","color":"#afaeae","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--silver","text":"Silver Players can start enjoying all our incredible opportunities!"},]
                    },
                    {
                        "3": [{"id": "36", "name": "GOLD", "dept": "100,000 - 499,999 TOKENS", "color":"#ceab49","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--gold","text":"Dedicated Players will elevate to Gold quickly and discover bigger benefits!"},]
                    },
                    {
                        "4": [{"id": "36", "name": "PLATINUM", "dept": "500,000 - 999,999 TOKENS", "color":"#8b8a8a","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--platinum","text":"There's more waiting for Platinum Players including additional Token Rewards!"},]
                    },
                    {
                        "5": [{"id": "50", "name": "EMERALD", "dept": "1,000,000 - 2,499,999 TOKENS", "color":"#53a564","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--emerald","text":"Surpassing Platinum, our Emerald Status unlocks even more amazing benefits!"},
                        ],
                    },
                    {
                        "6": [{"id": "50", "name": "AMETHYST", "dept": "2,500,000 - 4,999,999 TOKENS", "color":"#c36cd8","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--amethyst","text":"Surpassing Platinum, our Emerald Status unlocks even more amazing benefits!"},
                        ],
                    },
                    {
                        "7": [{"id": "50", "name": "SAPPHIRE", "dept": "5,000,000 - 24,999,999 TOKENS","color":"#6195f2","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--sapphire","text":"Our half-way mark -- Sapphires really get rewarded for their daily dedication!"},
                        ],
                    },
                    {
                        "8": [{"id": "50", "name": "RUBY", "dept": "25,000,000 - 49,999,999 TOKENS","color":"#f46767","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--ruby","text":"Ruby Players really can win big with OVER 800 PRIZES reserved just for them!"},
                        ],
                    },
                    {
                        "9": [{"id": "50", "name": "DIAMOND", "dept": "50,000,000 - 99,999,999 TOKENS","color":"#91acc9","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--diamond","text":"Shimmering Diamonds stand out as being some of our most active players!"},
                        ],
                    },
                    {
                        "10": [{"id": "50", "name": "YELLOW DIAMOND", "dept": "100,000,000 - 199,999,999 TOKENS","color":"#c9bc1c","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--yellow_diamond","text":"Just 3 from the top -- Yellow Diamonds are celebrated with BIG BENEFITS!"},
                        ],
                    },
                    {
                        "11": [{"id": "50", "name": "BLACK DIAMOND", "dept": "200,000,000 - 349,999,999 TOKENS","color":"#787777","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--black_diamond","text":"Just ONE Level from the top, BLACK DIAMONDS get the royal treatment!"},
                        ],
                    },
                    {
                        "12": [{"id": "50", "name": "RED DIAMOND", "dept": "350,000,000 + TOKENS","color":"#c64242","className":"rewardspage__carousel__levelicon rewardspage__carousel__levelicon--red_diamond","text":"Our ULTIMATE Level with BEST REWARDS reserved for our most dedicated!"},
                        ],
                    },
                ],
            week: {id: 0, title: "current week"}
        }}
    ,
    methods: {
        levelsFilter(w) {
            return this.levels[w]
        },
        selectlevel(w) {
            this.week.id += w
            this.week.id === 0 ? this.week.title = "current week" : this.week.id === 1 ? this.week.title = "next week" : this.week.title = "week after"
        },
        changeClass: function () {
            return this.class;
        },
        changeCitation(i){
            this.text=this.levels[2];
            console.log(this.levels[2]);
        }
    },
    mounted() {
        this.changeCitation();
    }
}
</script>
