<template>
    <form ref="form" name="psh-login-form" id="psh-login-form" class="psh-form" @submit="formSubmit" method="post" action="javascript:;" novalidate="true">
        <div id="main_content" class="main-content">
            <label for="EM">Email:</label>
            <input type="email" name="email" v-model="email" id="EM" maxlength="64" size="65" class="required" tabindex="4" style="height: inherit;">
            <span class="floating-placeholder" v-if="msg.email">{{msg.email}}</span>
            <label for="password">Password:</label>
            <input type="password" name="password" v-model="password" id="password" maxlength="64" size="65" autocomplete="on" class="required" tabindex="4" style="height: inherit;">
            <span class="floating-placeholder" v-if="msg.password">{{msg.password}}</span>

            <span class="floating-placeholder required" v-if="msg.error">{{msg.error}}</span>
            <div id="signed_in" class="pch-form-links">
                <input type="checkbox" name="remember_me" v-model="remember_me" id="RM" class="keep-me-signed-in" checked="checked" tabindex="4" style="height: inherit;">&nbsp;Keep me signed in.<br>
                <br>Forgot or need to reset your password?
                <a :href="route('official-login.forgotPass')">Click Here</a><br> <br>
                Need an account?
                <a :href="route('official-registration')">Register</a>
            </div>
        </div>
        <div class="submit-container">
            <div id="sub_btn" class="sub-btn gradient" v-on:click="submit">
                <div class="sub-btn-left">
                    <a class="cursor-pointer" id="login-sub-btn">Sign In</a>
                    <button type="submit" id="submit_form_login" class="hidden"></button>
                </div>
                <div class="sub-btn-right">
                    <div class="arrow"></div>
                </div>
            </div>
            <p class="copy"><a href="#" target="_blank">Privacy Policy</a></p>
        </div>
    </form>
</template>

<script>
    export default {
        data() {
            return  {
                email: null,
                password: null,
                remember_me: null,
                msg: [],
                formValidated: true,
            };
        },
        methods: {
            submit : function(){
                $('#submit_form_login').click();
            },
            async formSubmit() {
                var vm = this;
                await $.ajax({
                    method: 'POST',
                    url: route('official-login.store'),
                    data: {
                        email: this.email,
                        password: this.password,
                        remember_me: this.remember_me,
                    },
                }).done((response) => {
                    if (response.code === 400) {
                        vm.msg['error'] = response.message;
                    } else {
                        window.location.href = route('home', response.data);
                    }
                    vm.$forceUpdate();
                }).fail((xhr) => {
                    var errors = xhr.responseJSON.errors;
                    for (const [key, value] of Object.entries(errors)) {
                        vm.msg[key] = value[0];
                    }
                    vm.$forceUpdate();
                });
            },
        },
    };
</script>