require('./frontend');

import Vue from 'vue';

import Vuex from 'vuex';
import moment from 'moment';
import { notify, trans, chunk } from './functions';
import VueAxios from 'vue-axios';
import VueSimpleAlert from "vue-simple-alert";
import 'vue-loaders/dist/vue-loaders.css';
import VueLoaders from 'vue-loaders';
import 'animate.css';


new Vue({

})
Vue.use(VueLoaders);
import axios from "axios";
Vue.use(VueSimpleAlert, { reverseButtons: true });

Vue.filter('formatDate', function(value, format = null) {
    if (value) {
        return moment(String(value)).format(format || 'MM/DD/YYYY hh:mm');
}});

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.headers.common['X-CSRF-TOKEN'] = ThePsh.csrfToken;

Vue.use(VueAxios, window.axios);

Vue.prototype.route = route;
Vue.prototype.$notify = notify;
Vue.prototype.$trans = trans;
Vue.prototype.$chunk = chunk;
Vue.prototype.$axios = axios;

Vue.use(Vuex);
window.Vuex = Vuex;
window.Vue = Vue;
