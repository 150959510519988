<template>
    <form @submit.prevent="placeOrder" @input="errors.clear($event.target.name)" id="submitCyberSource"
          name="submitCyberSource">
        <input type="hidden" name="billing[title]" v-model="form.billing.title">
        <input type="hidden" name="billing[first_name]" v-model="form.billing.first_name">
        <input type="hidden" name="billing[last_name]" v-model="form.billing.last_name">
        <input type="hidden" name="billing[city]" v-model="form.billing.city">
        <input type="hidden" name="billing[country]" v-model="form.billing.country">
        <input type="hidden" name="billing[zip]" v-model="form.billing.zip">
        <input type="hidden" name="billing[address_1]" v-model="form.billing.address_1">
        <input type="hidden" name="billing[address_2]" v-model="form.billing.address_2">
        <input type="hidden" name="billing[state]" v-model="form.billing.state">
        <div id="ccfm_new_card_form_shipping" class="sha mt-3 show">
            <div class="sha-header">
                <div class="sha-header-checkmark">
                    <img alt="" src="/images/main/check_mark.png">
                </div>
                <h3 class="h-sec-bld sha-header-text">Shipping Address</h3>
                <!--<div class="wr-add" :class="{ 'hidden': isMobile}">Wrong Address? <span
                        class="wr-add-cl">Click Here</span></div>
                <div class="wr-add-sh wr-add-cl" :class="{ 'hidden': isMobile}">Wrong Address?</div>-->
            </div>
            <address class="sha-body">
                <span class="sha-ship-name" v-text="this.user.title + this.user.first_name +this.user.first_name "></span>
                <span class="sha-ship-address-1" v-text="this.user.address">  &nbsp;</span>
                <span class="sha-ship-address-2" v-text="this.user.city +', '+ this.user.state + ' '+ this.user.zip_code"> </span>
            </address>
        </div>
        <div class="scm mt-2">
            <div class="scm-header">
                <div class="scm-header-checkmark">
                    <img alt="" src="/images/main/check_mark.png">
                </div>
                <h3 class="h-sec-bld scm-header-text">Your Order Summary</h3>
            </div>

            <ul class="scm-list">

                <li class="scm-list-item" v-for="item in cart.items" :key="item.id">
                    <div class="scm-list-item__body">
                        <span class="scm-list-item--num">1.</span>
                        <span data-prop="description" class="scm-list-item--desc">
                            <span v-text="item.product.name"></span>
                            <span class="scm-list-item-price"> Item Price:  <span v-text="item.product.price"></span></span>
                        </span>
                        <span class="scm-list-item--quto">
                            <span data-prop="quantity" class="scm-list-item--qty">
                                <span class="scm-list-item--qty-muted">QTY: <span v-text="item.qty"></span></span>
                            </span>
                            <span data-prop="total" class="scm-list-item--tot"> <span v-text="item.total.formatted"></span></span>
                        </span>
                    </div>
                    <div class="scm-list-item__footer">
                    </div>
                </li>
            </ul>
        </div>

        <div class="tdm mt-2">
            <div class="tdm-totals">
                <ul class="tdm-totals-list">
                    <li class="tdm-totals-list-item">
                        <span data-prop="subtotal-desc">Sub Total (<span class="p-0" v-text="cart.quantity"></span> items)</span>
                        <span data-prop="subtotal"><span v-text="cart.subTotal.formatted"></span></span>
                    </li>

                </ul>

                <div class="dt-only mt-2 hr-line" v-if="cart.taxes.length >0"></div>

                <ul class="tdm-totals-list mt-3" v-if="cart.taxes.length >0">
                    <li class="tdm-totals-list-item">
                        <span data-prop="estimated-tax-desc">Estimated Tax</span>
                        <span data-prop="estimated-tax"><span v-text="cart.taxes[1].amount.formatted"></span></span>
                    </li>
                </ul>
                <div class="dt-only mt-2 hr-line"></div>

                <div class="shipping-methods mt-3" v-cloak>
                    <h6>Shipping Methods</h6>

                    <div class="form-group" v-if="hasShippingMethod">
                        <div class="form-radio" v-for="shippingMethod in cart.availableShippingMethods">
                            <input
                                    type="radio"
                                    name="shipping_method"
                                    v-model="form.shipping_method"
                                    :value="shippingMethod.name"
                                    :id="shippingMethod.name"
                                    @change="updateShippingMethod(shippingMethod)"
                            >

                            <label :for="shippingMethod.name" v-text="shippingMethod.label"></label>

                            <span
                                    class="price-amount"

                            ><span v-html="shippingMethod.cost.inCurrentCurrency.formatted"></span></span>
                        </div>
                    </div>

                    <span class="error-message" v-else>
                    Shipping method is not configured
                </span>
                </div>

                <div class="dt-only mt-2 hr-line"></div>

                <ul class="tdm-totals-list tdm-final-list mt-3">
                    <li class="tdm-totals-list-item">
                        <span data-prop="total-cart-desc">Total</span>
                        <span data-prop="total-cart"><span v-text="cart.total.formatted"></span></span>
                    </li>
                </ul>
            </div>
        </div>

        <div class="tdm-legal-wr">
            <p class="tdm-legal">
                Applicable sales tax will be added where required by law; for additional
                information, please go to <a
                    href="http://thepsh.custhelp.com/app/answers/detail/a_id/160/kw/sales%20tax"
                    target="_blank">thepsh.custhelp.com</a>. <br>
                Shipping, Handling, and Processing estimated based upon final processing.
            </p>
        </div>
        <div id="credit-card-partial" class="braintree-hosted-page">
            <div class="ccfm mt-2">
                <div id="ccfm_saved_cc_profiles" class="ccfm-saved-profiles"></div>

                <div id="ccfm_new_card_form" class="ccfm--pay-with-new-card show">
                    <input type="radio" name="payment_profile_token" data-cc-id="NEW"
                           value="NEW" style="display:none">

                    <div class="ccfm-form-divider">
                        <div class="ccfm-form-divider--header">
                            <div class="header-left">
                                <div class="ccfm-form-divider--header-exclamation">
                                    <img alt="" src="/images/main/exclamation.png">
                                </div>
                                <p class="h-sec-bld ccfm-form-divider--header-text">For Your Completion</p>
                            </div>
                            <div class="row-fluid ccfm--pay-with-new-card"
                                 style="padding-bottom: 15px">
                                <button id="ccfm_show_saved_card_btn"
                                        class="ccfm--pay-with-new-card--btn ccfm-show-saved-card"
                                        type="button">Show my saved cards
                                </button>
                            </div>
                        </div>
                    </div>

                    <div class="credit-section-container">
                        <div class="credit-section">
                            <div class="credit-fields-container">
                                <!--<stripe-element-card
                                        ref="elementRef"
                                        :pk="publishableKey"
                                        :sessionid="sessionId"
                                />-->
                                <!--<button @click="submit">Generate token</button>-->
                                <div class="payment-method" v-cloak>
                                    <h4 class="title">Payment method</h4>

                                    <div class="payment-method-form">
                                        <div class="form-group">
                                            <div class="form-radio" v-for="(gateway, name) in gateways">
                                                <input
                                                        type="radio"
                                                        name="payment_method"
                                                        v-model="form.payment_method"
                                                        :value="name"
                                                        :id="name"
                                                >

                                                <label :for="name" v-text="gateway.label"></label>
                                                <span class="helper-text" v-text="gateway.description"></span>
                                            </div>

                                            <span class="error-message" v-if="hasNoPaymentMethod">
                    No payment method is found.
                </span>
                                        </div>
                                    </div>
                                </div>

                                <!--<div id="stripe-card-element" v-show="form.payment_method === 'stripe'" v-cloak>
                                    A Stripe Element will be mounted here dynamically.
                                </div>-->

                                <span class="error-message" v-if="stripeError" v-text="stripeError"></span>

                                <div class="payment-instructions" v-if="shouldShowPaymentInstructions" v-cloak>
                                    <h4 class="title">Payment Instructions</h4>

                                    <p v-html="paymentInstructions"></p>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!--<div class="ccfm-inc-cb">
                        <div class="ccfm-inc-cb_arrow-left bounceArrowLeft">
                            <div class="arrow1"></div>
                            <div class="arrow2"></div>
                            <div class="arrow3"></div>
                        </div>
                        <div class="ccfm-inc-cb_chk">
                            <input id="ChkSaveToken" name="ChkSaveToken" type="checkbox"
                                   class="ccfm-inc-cb_chk-cb">
                            <label for="ChkSaveToken"></label>
                            <input type="hidden" id="merchant_defined_data20"
                                   name="merchant_defined_data20">
                        </div>
                        <div class="ccfm-inc-cb_center">
                        <span class="ccfm-footer-ic-wrap-content">
                            YES! SAVE MY BILLING AND CREDIT CARD INFO FOR A FAST AND EASY 1‑CLICK CHECKOUT NEXT TIME.
                        </span>
                        </div>
                    </div>-->
                </div>
            </div>
            <div id="validation-cont-two"></div>
        </div>
        <div id="ccfm_new_card_form_billing" class="sba mt-3 show">
            <div data-prop="billing-address" class="col-12 sba-billing-address">
                <header data-prop="header" class="row sba-billing-address--header">
                    <div>
                        <h3 class="header-text">Billing Address</h3>
                    </div>
                    <div class="sba-billing-address-select-same">
                        <input id="sas_checkbox"
                               name="ship_to_a_different_address"
                               v-model="form.ship_to_a_different_address"
                               class="cco2-checkbox diff-add" type="checkbox">
                        <label for="sas_checkbox">Check here if different than shipping address</label>
                    </div>
                </header>

                <div class="sba-billing-address--form"  v-show="form.ship_to_a_different_address" v-cloak>
                    <div class="sba-form row">
                        <!--<label for="shipping-first-title" class="screen-reader-text">Select Title</label>-->
                        <select class="col-md-4"
                                name="shipping[title]"
                                v-model="form.shipping.title"
                                id="shipping-first-title"
                                :required="form.ship_to_a_different_address">
                            <option label="Title" value="" disabled="">Title</option>
                            <option label="Mr." value="Mr.">Mr.</option>
                            <option label="Mrs." value="Mrs.">Mrs.</option>
                            <option label="Ms." value="Ms.">Ms.</option>
                        </select>
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.title')"
                                v-text="errors.get('shipping.title')"
                        ></span>

                        <!--<label for="shipping-first-name" class="screen-reader-text">First Name</label>-->
                        <input class="col-md-4"
                               name="shipping[first_name]"
                               v-model="form.shipping.first_name"
                               id="shipping-first-name"
                               type="text"
                               placeholder="First Name"
                               pattern="(.*)([a-zA-Z]+)(.*)([a-zA-Z]+)(.*)"
                               title="Please enter your first name as it appears on your card."
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.first_name')"
                                v-text="errors.get('shipping.first_name')"
                        >
                            </span>

                        <!--<label for="shipping-last-name" class="screen-reader-text">Last Name</label>-->
                        <input class="col-md-4"
                               name="shipping[last_name]"
                               v-model="form.shipping.last_name"
                               id="shipping-last-name"
                               type="text"
                               pattern="(.*)([a-zA-Z]+)(.*)([a-zA-Z]+)(.*)"
                               placeholder="Last Name"
                               title="Please enter your last name as it appears on your card."
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.last_name')"
                                v-text="errors.get('shipping.last_name')"
                        >
                            </span>
                    </div>
                    <div class="sba-form row">
                        <!--<label for="shipping-address-1" class="screen-reader-text">Billing address 1</label>-->
                        <input class="col-md-12"
                               name="shipping[address_1]"
                               v-model="form.shipping.address_1"
                               id="shipping-address-1"
                               type="text"
                               placeholder="Address"
                               pattern="[\w\s&amp;-\.]{2,}"
                               title="Please enter the billing address for the credit card you entered."
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.address_1')"
                                v-text="errors.get('shipping.address_1')"
                        >
                    </span>
                    </div>
                    <div class="sba-form row">
                        <!--<label for="shipping-address-2" class="screen-reader-text">Billing address 2 (optional)</label>-->
                        <input class="col-md-12"
                               name="shipping[address_2]"
                               id="shipping-address-2"
                               v-model="form.shipping.address_2"
                               type="text"
                               placeholder="Address Line 2"
                               title="Please enter the billing address 2 for the credit card you entered."
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.address_2')"
                                v-text="errors.get('shipping.address_2')"
                        >
                    </span>
                    </div>
                    <div class="sba-form row">
                        <!--<label for="shipping-city" class="screen-reader-text">City</label>-->
                        <input class="col-md-3"
                               name="shipping[city]"
                               :value="form.shipping.city"
                               id="shipping-city"
                               type="text"
                               placeholder="City"
                               pattern="[\w\s&amp;-\.]{2,}"
                               title="Please enter the billing city for the credit card you entered."
                               @change="changeShippingCity($event.target.value)"
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.city')"
                                v-text="errors.get('shipping.city')"
                        >
                    </span>

                        <!--<label for="shipping-country" class="screen-reader-text">City</label>-->
                        <select
                                name="shipping[country]"
                                :value="form.shipping.country"
                                id="shipping-country"
                                class="col-md-3"
                                @change="changeShippingCountry($event.target.value)"
                        >
                            <option
                                    v-for="(name, code) in countries"
                                    :value="code"
                                    v-text="name"
                            >
                            </option>
                        </select>
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.city')"
                                v-text="errors.get('shipping.city')"
                        >
                    </span>

                        <label for="shipping-state" class="screen-reader-text">State</label>

                        <select
                                name="shipping[state]"
                                v-model="form.shipping.state"
                                id="shipping-state"
                                class="col-md-2"
                                :required="form.ship_to_a_different_address"
                        >
                            <option label="State" value="" disabled="">State</option>
                            <option value="AL">AL</option><option value="AK">AK</option><option value="AZ">AZ</option><option value="AR">AR</option><option value="CA">CA</option><option value="CO">CO</option><option value="CT">CT</option><option value="DC">DC</option><option value="DE">DE</option><option value="FL">FL</option><option value="GA">GA</option><option value="HI">HI</option><option value="ID">ID</option><option value="IL">IL</option><option value="IN">IN</option><option value="IA">IA</option><option value="KS">KS</option><option value="KY">KY</option><option value="LA">LA</option><option value="ME">ME</option><option value="MD">MD</option><option value="MA">MA</option><option value="MI">MI</option><option value="MN">MN</option><option value="MS">MS</option><option value="MO">MO</option><option value="MT">MT</option><option value="NE">NE</option><option value="NV">NV</option><option value="NH">NH</option><option value="NJ">NJ</option><option value="NM">NM</option><option value="NY">NY</option><option value="NC">NC</option><option value="ND">ND</option><option value="OH">OH</option><option value="OK">OK</option><option value="OR">OR</option><option value="PA">PA</option><option value="PR">PR</option><option value="RI">RI</option><option value="SC">SC</option><option value="SD">SD</option><option value="TN">TN</option><option value="TX">TX</option><option value="UT">UT</option><option value="VT">VT</option><option value="VA">VA</option><option value="VI">VI</option><option value="WA">WA</option><option value="WV">WV</option><option value="WI">WI</option><option value="WY">WY</option>
                        </select>

                        <span
                                class="error-message"
                                v-if="errors.has('shipping.state')"
                                v-text="errors.get('shipping.state')"
                        >
                            </span>

                        <!--<label for="shipping-zip" class="screen-reader-text">State</label>-->
                        <input class="col-md-3"
                               name="shipping[zip]"
                               v-model="form.shipping.zip"
                               id="shipping-zip"
                               type="text"
                               placeholder="Zip"
                               maxlength="5"
                               pattern="\d{5}"
                               title="Please enter a valid 5 digit Zip Code."
                               :required="form.ship_to_a_different_address">
                        <span
                                class="error-message"
                                v-if="errors.has('shipping.zip')"
                                v-text="errors.get('shipping.zip')"
                        >
                            </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="osm order-summary-bottom mt-3">
            <div class="form-group checkout-terms-and-conditions">
                <div class="form-check">
                    <input type="checkbox" v-model="form.terms_and_conditions" id="terms-and-conditions">

                    <label for="terms-and-conditions" class="form-check-label">
                        I agree to the
                        <a :href="route('terms')" target="_blank">
                            Terms & Conditions
                        </a>
                    </label>

                    <span class="error-message" v-if="errors.has('terms_and_conditions')" v-text="errors.get('terms_and_conditions')"></span>
                </div>
            </div>

            <div id="paypal-button-container" v-if="form.payment_method === 'paypal'"></div>

            <button
                    type="submit"
                    class="btn btn-primary btn-place-order"
                    :class="{ 'btn-loading': placingOrder }"
                    :disabled="! form.terms_and_conditions || ! hasShippingMethod || placingOrder"
                    v-else
                    v-cloak
            >
                Complete Checkout
            </button>
        </div>
    </form>
</template>
<script>
import store from '../../store';
import Errors from '../../Errors';
import CartHelpersMixin from "@/components/mixins/CartHelpersMixin";


export default {

    props: ['gateways', 'countries', 'user'],

    mixins: [
        CartHelpersMixin,
    ],

    data() {
        return {
            publishableKey: ThePsh.stripePublishableKey,
            sessionId: null,
            form: {
                billing: {
                    title: this.user.title,
                    first_name: this.user.first_name,
                    last_name: this.user.last_name,
                    address_1: this.user.address,
                    address_2: this.user.apt_suite,
                    city: this.user.city,
                    country: this.user.country,
                    state: this.user.state,
                    zip: this.user.zip_code,
                },
                shipping: {},
            },
            states: {
                billing: {},
                shipping: {},
            },
            placingOrder: false,
            errors: new Errors(),
            stripe: null,
            stripeCardElement: null,
            stripeError: null,
            isRequired:null,
            isMobile: ThePsh.is_mobile
        };
    },

    computed: {
        /*cart() {
            return store.state.cart;
        },*/
        firstCountry() {
            return Object.keys(this.countries)[0];
        },

        hasNoPaymentMethod() {
            return Object.keys(this.gateways).length === 0;
        },

        firstPaymentMethod() {
            return Object.keys(this.gateways)[0];
        },

        /*hasShippingMethod() {
            return store.hasShippingMethod();
        },*/

        shouldShowPaymentInstructions() {
            return ['bank_transfer', 'check_payment'].includes(this.form.payment_method);
        },

        paymentInstructions() {
            if (this.shouldShowPaymentInstructions) {
                return this.gateways[this.form.payment_method].instructions;
            }
        },
        /*hasCoupon() {
            return store.state.cart.coupon.code !== undefined;
        },*/
    },

    watch: {
        'form.billing.city': function (newCity) {
            if (newCity) {
                /*this.addTaxes();*/
            }
        },

        'form.shipping.city': function (newCity) {
            if (newCity) {
                /*this.addTaxes();*/
            }
        },

        'form.billing.zip': function (newZip) {
            if (newZip) {
                /*this.addTaxes();*/
            }
        },

        'form.shipping.zip': function (newZip) {
            if (newZip) {
                /*this.addTaxes();*/
            }
        },

        'form.billing.state': function (newState) {
            if (newState) {
                /*this.addTaxes();*/
            }
        },

        'form.shipping.state': function (newState) {
            if (newState) {
                /*this.addTaxes();*/
            }
        },

        'form.ship_to_a_different_address': function ($ship_to_a_different_address) {
            // this.addTaxes();
            if ($ship_to_a_different_address) {
                this.isRequired = 'required=""';
            }else {
                this.isRequired = null;
            }
        },

        'form.terms_and_conditions': function () {
            this.errors.clear('terms_and_conditions');
        },

        'form.payment_method': function (newPaymentMethod) {
            if (newPaymentMethod === 'paypal') {
                this.$nextTick(this.renderPayPalButton);
            }

            if (newPaymentMethod !== 'stripe') {
                this.stripeError = '';
            }
        },
    },

    created() {
        this.changeShippingCountry(this.firstCountry);

        this.$nextTick(() => {
            if (this.firstPaymentMethod) {
                this.changePaymentMethod(this.firstPaymentMethod);
            }

            if (this.firstShippingMethod) {
                this.updateShippingMethod(this.firstShippingMethod);
            }

            if (window.Stripe) {
                this.stripe = window.Stripe(this.publishableKey);
            }
        });
    },

    methods: {

        changeShippingCity(city) {
            this.$set(this.form.shipping, 'city', city);
        },

        changeShippingZip(zip) {
            this.$set(this.form.shipping, 'zip', zip);
        },

        changeShippingCountry(country) {
            this.$set(this.form.shipping, 'country', country);

            this.fetchStates(country, (states) => {
                this.$set(this.states,'shipping', states);
                this.$set(this.form.shipping, 'state', '');
            });
        },

        changeShippingState(state) {
            this.$set(this.form.shipping, 'state', state);
        },

        changePaymentMethod(paymentMethod) {
            this.$set(this.form, 'payment_method', paymentMethod);
        },

        fetchStates(country, callback) {
            $.ajax({
                method: 'GET',
                url: route('countries.states.index', {code: country}),
            }).then(callback);
        },

        changeShippingMethod(shippingMethod) {
            this.$set(this.form, 'shipping_method', shippingMethod.name);
        },

        addTaxes() {
            this.loadingOrderSummary = true;

            $.ajax({
                method: 'POST',
                url: route('cart.taxes.store'),
                data: this.form,
            }).then((cart) => {
                store.updateCart(cart);
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.loadingOrderSummary = false;
            });
        },

        placeOrder() {

            if (!this.form.terms_and_conditions) {
                return;
            }

            this.placingOrder = true;

            $.ajax({
                method: 'POST',
                url: route('checkout.store'),
                data: this.form,
            }).then((response) => {
                window.location.href = response.sessionResponse.url;
            }).catch((xhr) => {
                if (xhr.status === 422) {
                    this.errors.record(xhr.responseJSON.errors);
                }

                this.$notify(xhr.responseJSON.message);

                this.placingOrder = false;
            });
        }
    },
}
</script>