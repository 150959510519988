<template>
    <p class="uninav__token-center-alltime__tokens-amount uninav__token-balance__amount" data-current="loading" id="tokens_quota_id" data-total="loading" v-html="formatPriceHtml(tokens_quota)"></p>
</template>

<script>
    export default {

        data() {
            return {
                tokens_quota: window.ThePsh.tokens_quota
            };
        },

        computed: {

        },

        watch: {

        },

        mounted: function () {
            this.getTokensQuota();
            this.$nextTick(function() {
                if (typeof window.addEventListener !== 'undefined') {

                    window.addEventListener('message', this.receiveMessage, false);

                } else if (typeof window.attachEvent !== 'undefined') {

                    window.attachEvent('onmessage', this.receiveMessage);
                }
            })
        },

        methods: {
            receiveMessage (event) {
                if (event.data.event_id === 'game_event'){
                    this.getTokensQuota();
                    if (event.data.game_type === 'lotto'){
                        this.tokensLottoNotification();
                        /*if (window.ThePsh.loggedIn){
                            /!*window.location.reload();*!/
                        }else{
                            window.location.href = route('official-registration');
                        }*/
                    }else if(event.data.game_type === 'search'){
                        this.tokensSearchNotification();
                    }else{
                        this.tokensNotification();
                    }
                    this.showCelebrateLogo();
                }
            },
            getTokensQuota () {
                fetch(route('games.get-score',window.ThePsh.user))
                    .then(response => response.json())
                    .then(data => {
                        if (data.score !== 0){
                            this.tokens_quota = data.score;
                        }
                    });
            },
            tokensNotificationV1 () {
                let position = 'top-end';
                let mobileClass = 'size-desktop-img';

                if (ThePsh.is_mobile === "1"){
                    position = 'center';
                    mobileClass  = 'size-mobile-img'
                }
                this.$fire({
                    position: position,
                    imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                    animation: false,
                    showConfirmButton:false,
                    showCancelButton: false,
                    cancelButtonText:
                        '<i class="fa fa-thumbs-up"></i> Keep playing!',
                    confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                    confirmButtonColor: '#f9791d',
                    cancelButtonColor: '#f4b007',
                    customClass: {
                        popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                        image: mobileClass,
                    },
                    html: `
                  <div class="celebrate-actions">
                    <button type="button" class="swal2-info swal2-styled" onclick="onCelebrateBtnClicked('redeem')"><i>Redeem tokens!</i></button>
                    <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep playing!</i></button>
                    <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateBtnClicked('next')"><i>Next game!</i></button>
                    <button type="button" class="swal2-deny swal2-styled" onclick="onCelebrateBtnClicked('other')"><i>Other games!</i></button>
                  </div>`
                });
            },
            tokensNotification () {
                let position = 'center';
                let mobileClass = 'size-desktop-img';

                if (ThePsh.is_mobile === "1"){
                    position = 'center';
                    mobileClass  = 'size-mobile-img'
                }
                this.$fire({
                    title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                    icon: 'info',
                    position: position,
                    // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                    animation: false,
                    showConfirmButton:false,
                    showCancelButton: false,
                    cancelButtonText:
                        '<i class="fa fa-thumbs-up"></i> Keep playing!',
                    confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                    confirmButtonColor: '#f9791d',
                    cancelButtonColor: '#f4b007',
                    customClass: {
                        popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                        image: mobileClass,
                    },
                    html: ` You have earned new tokens. Your token wallet now is <b>`+this.tokens_quota+`</b> tokens.
                        Try to <a href="`+route('rewards')+`">WIN OUR SUPER PRIZE</a> or <a href="`+route('games.index')+`">PLAY MORE GAMES</a> to win other tokens.'
                      <div class="celebrate-actions mt-4">
                        <button type="button" class="swal2-info swal2-styled" onclick="onCelebrateBtnClicked('redeem')"><i>Redeem tokens!</i></button>
                        <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep playing!</i></button>
                        <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateBtnClicked('next')"><i>Next game!</i></button>
                        <button type="button" class="swal2-deny swal2-styled" onclick="onCelebrateBtnClicked('other')"><i>Other games!</i></button>
                      </div>
                    `
                });
            },
            tokensLottoNotification () {
                let position = 'center';
                let mobileClass = 'size-desktop-img';

                if (ThePsh.is_mobile === "1"){
                    position = 'center';
                    mobileClass  = 'size-mobile-img'
                }
                this.$fire({
                    title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                    icon: 'info',
                    position: position,
                    // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                    animation: false,
                    showConfirmButton:false,
                    showCancelButton: false,
                    cancelButtonText:
                        '<i class="fa fa-thumbs-up"></i> Keep playing!',
                    confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                    confirmButtonColor: '#f9791d',
                    cancelButtonColor: '#f4b007',
                    customClass: {
                        popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                        image: mobileClass,
                    },
                    html: `You have earned new tokens. Your token wallet now is <b>`+this.tokens_quota+`</b> tokens.
                        Try to <a href="`+route('rewards')+`">WIN OUR SUPER PRIZE</a> or <a href="`+route('lotto.index')+`">PLAY MORE LOTTO GAMES</a> to win other tokens.'
                      <div class="celebrate-actions mt-4">
                        <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateLottoBtnClicked('redeem')"><i>Redeem Tokens</i></button>
                        <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateLottoBtnClicked('next')"><i>Next game!</i></button>
                        <button type="button" class="swal2-deny swal2-styled" onclick="onCelebrateLottoBtnClicked('other')"><i>Other games!</i></button>
                      </div>`
                });
            },
            tokensSearchNotification () {
                let vm = this;
                let position = 'center';
                let mobileClass = 'size-desktop-img';

                if (ThePsh.is_mobile === "1"){
                    position = 'center';
                    mobileClass  = 'size-mobile-img'
                }
                this.$fire({
                    title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                    icon: 'info',
                    position: position,
                    // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                    animation: false,
                    showConfirmButton:false,
                    showCancelButton: false,
                    cancelButtonText:
                        '<i class="fa fa-thumbs-up"></i> Keep searching!',
                    confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                    confirmButtonColor: '#f9791d',
                    cancelButtonColor: '#f4b007',
                    customClass: {
                        popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                        image: mobileClass,
                    },
                    html: `You have earned new tokens. Try to redeem your tokens and <a href="`+route('rewards')+`">WIN OUR SUPER PRIZE</a> or <a href="javascript:;" onclick="onCelebrateBtnClicked('keep')">KEEP SEARCHING</a> to win other tokens.'
                  <div class="celebrate-actions">
                    <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateLottoBtnClicked('redeem')"><i>Redeem Tokens</i></button>
                    <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep searching!</i></button>
                  </div>`
                });
            },
            showCelebrateLogo () {
                $('.uninav__token-balance__amount').addClass('celebrate');
            },
            formatPrice(value) {
                return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            formatPriceHtml(value){
                let price = this.formatPrice(value);
                const priceDigit = price.split('');
                let token_html = "";
                priceDigit.forEach(function (value,i) {
                    token_html += "<span style='--i:"+i+"'>" + value + "</span>";
                });

                return token_html;
            }
        },
        beforeDestroy: {
            removeCallbackTokensQuota () {
                if (typeof window.addEventListener !== 'undefined') {
                    window.removeEventListener('message', this.receiveMessage)

                } else if (typeof window.attachEvent !== 'undefined') {
                    window.removeEventListener('message', this.receiveMessage)
                }
            }
        }
    }
</script>