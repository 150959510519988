import Vue from 'vue';
import { isEmpty } from './functions';
export default {

    state: Vue.observable({
        cart: ThePsh.cart,
    }),

    cartIsEmpty() {
        return isEmpty(this.state.cart.items);
    },

    updateCart(cart) {
        this.state.cart = cart;
    },

    removeCartItem(cartItem) {
        Vue.delete(this.state.cart.items, cartItem.id);
    },

    clearCart() {
        this.state.cart.items = {};
    },

    hasShippingMethod() {
        return Object.keys(this.state.cart.availableShippingMethods).length !== 0;
    }
};
