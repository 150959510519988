<template>
    <article class="main__body main__body--desktop">
        <section class="my-account">
            <div id="app">
                <header class="data-v-201d486e bis_skin">
                    <h1 class="data-v-201d486e">My PSH Account</h1>
                    <p class="email data-v-201d486e">
                        <span class="data-v-201d486e">{{post.email}}</span>
                        <a href="" class="data-v-201d486e">Edit</a>
                    </p></header>
                <section class="data-v-8f1b00a4 data-v-51db9a34">
                    <header class="data-v-8f1b00a4"><h2 class="data-v-8f1b00a4">
                        My Info</h2>
                        <div class="data-v-8f1b00a4">
                            <a class="exitModal data-v-8f1b00a4 "></a></div>
                        <div class="email data-v-8f1b00a4">
                            <span class="data-v-8f1b00a4">{{post.email}}</span>
                            <a :href="route('account.NewEmail')">Edit</a>
                        </div>
                    </header>
                    <div class="content data-v-ae1b3624 data-v-51db9a34">
                        <header class="data-v-ae1b3624">
                            <header class="my-info data-v-51db9a34">
                                <h2 class="sectionPadding data-v-51db9a34">

                                </h2></header>
                            <span class="data-v-ae1b3624">
                                <span class="arrow data-v-ae1b3624"></span></span></header>
                        <main class="data-v-ae1b3624">
                            <main class="my-info-container data-v-51db9a34">
                                <section class="my-info data-v-927c11a4">
                                    <div class="my-info__sighted-legend data-v-927c11a4" aria-hidden=""
                                        >
                                        <div class="data-v-927c11a4">Name:</div>
                                        <div class="data-v-927c11a4">Address:</div>
                                        <div class="data-v-927c11a4">Birthday:</div>
                                    </div>

                                    <form class="my-info__form pure-form pure-form-stacked" @submit.prevent="updatePost"
                                          action="" method="POST" data-v-927c11a4="">
                                        <input type="hidden" name="_token" value="">
                                        <fieldset class="data-v-927c11a4">
                                            <legend class="my-info__lowsight-legend data-v-927c11a4"> Name:</legend>
                                            <div class="my-info__grid pure-g data-v-927c11a4">
                                                <div class="pure-u-1-3 pure-u-md-3-24 data-v-927c11a4"
                                                    >
                                                    <div id=""
                                                         class="base-select__container data-v-5e99c064 data-v-927c11a4"
                                                         name="title">
                                                        <div class="base-select data-v-5e99c064">
                                                            <label class="base-select__label data-v-5e99c064"
                                                                   for="title">Title</label>
                                                            <select name="title" v-model="post.title" id="title"
                                                                    class="base-select__select data-v-5e99c064"
                                                                    tabindex="0">
                                                                <option selected="selected" hidden>{{post.title}}
                                                                </option>
                                                                <option value="Mr." class="data-v-5e99c064">Mr.</option>
                                                                <option value="Mrs." class="data-v-5e99c064">Mrs.
                                                                </option>
                                                                <option value="Ms." class="data-v-5e99c064">Ms.</option>
                                                            </select></div>
                                                        <div class="base-select__spacer data-v-5e99c064"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="my-info__field--mid pure-u-1 pure-u-md-10-24 data-v-927c11a4"
                                                    >
                                                    <div id=""
                                                         class="base-input__container data-v-74b77462 data-v-927c11a4"
                                                         name="first_name">
                                                        <div class="base-input data-v-74b77462">
                                                            <label class="base-input__label data-v-74b77462" for="">First
                                                                Name</label>
                                                            <input name="first_name" id="first_name" type="text"
                                                                   placeholder="First Name" v-model="post.first_name"
                                                                   class="base-input__input data-v-74b77462"
                                                                   tabindex="0"><!--v-if--></div>
                                                        <div class="base-input__spacer data-v-74b77462"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="pure-u-1 pure-u-md-11-24 data-v-927c11a4"
                                                    >
                                                    <div id=""
                                                         class="base-input__container data-v-74b77462 data-v-927c11a4"
                                                         name="last_name">
                                                        <div class="base-input data-v-74b77462">
                                                            <label class="base-input__label data-v-74b77462" for="">Last
                                                                Name</label>
                                                            <input name="" type="text" placeholder="Last Name"
                                                                   v-model="post.last_name"
                                                                   class="base-input__input data-v-74b77462"
                                                                   tabindex="0"><!--v-if--></div>
                                                        <div class="base-input__spacer data-v-74b77462"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset data-v-927c11a4="">
                                            <legend class="my-info__lowsight-legend" data-v-927c11a4=""> Address:
                                            </legend>
                                            <div class="my-info__grid pure-g" data-v-927c11a4="">
                                                <div class="my-info__field--start pure-u-1 pure-u-md-19-24"
                                                     data-v-927c11a4="">
                                                    <div id="" class="base-input__container" name="address"
                                                         data-v-74b77462="" data-v-927c11a4="">
                                                        <div class="base-input" data-v-74b77462="">
                                                            <label class="base-input__label" for="" data-v-74b77462="">Street
                                                                Address</label><input name="address"
                                                                                      v-model="post.address" type="text"
                                                                                      placeholder="Street Address"
                                                                                      class="base-input__input"
                                                                                      tabindex="0" data-v-74b77462="">
                                                            <!--v-if--></div>
                                                        <div class="base-input__spacer" data-v-74b77462=""
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="pure-u-1 pure-u-md-5-24" data-v-927c11a4=""
                                                    >
                                                    <div id="" class="base-input__container" name="app_suite"
                                                         data-v-74b77462="" data-v-927c11a4="">
                                                        <div class="base-input" data-v-74b77462="">
                                                            <!--v-if--><input name="apt_suite" id="apt_suite"
                                                                              v-model="post.apt_suite" type="text"
                                                                              placeholder="Apt/Suite"
                                                                              class="base-input__input" tabindex="0"
                                                                              data-v-74b77462=""><!--v-if--></div>
                                                        <div class="base-input__spacer" data-v-74b77462=""
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="pure-u-1 pure-u-md-13-24" data-v-927c11a4=""
                                                    >
                                                    <div id="" class="base-input__container" name="city"
                                                         data-v-74b77462="" data-v-927c11a4="">
                                                        <div class="base-input" data-v-74b77462="">
                                                            <label class="base-input__label" for="" data-v-74b77462="">City</label><input
                                                                name="" id="" type="text" placeholder="City"
                                                                v-model="post.city" class="base-input__input"
                                                                tabindex="0" data-v-74b77462=""><!--v-if--></div>
                                                        <div class="base-input__spacer" data-v-74b77462=""
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="my-info__field--mid pure-u-1 pure-u-md-5-24"
                                                     data-v-927c11a4="">
                                                    <div class="base-select__container" name="state" data-v-5e99c064=""
                                                         data-v-927c11a4="">
                                                        <div class="base-select" data-v-5e99c064=""
                                                            ><label class="base-select__label"
                                                                                         for="state" data-v-5e99c064="">State</label><select
                                                                name="state" id="state" v-model="post.state"
                                                                class="base-select__select" tabindex="0"
                                                                data-v-5e99c064="">
                                                            <option selected="selected" hidden>{{post.state}}</option>
                                                            <option data-v-5e99c064=""></option>
                                                            <option value="AL" data-v-5e99c064="">AL</option>
                                                            <option value="AK" data-v-5e99c064="">AK</option>
                                                            <option value="AZ" data-v-5e99c064="">AZ</option>
                                                            <option value="AR" data-v-5e99c064="">AR</option>
                                                            <option value="CA" data-v-5e99c064="">CA</option>
                                                            <option value="CO" data-v-5e99c064="">CO</option>
                                                            <option value="CT" data-v-5e99c064="">CT</option>
                                                            <option value="DE" data-v-5e99c064="">DE</option>
                                                            <option value="DC" data-v-5e99c064="">DC</option>
                                                            <option value="FL" data-v-5e99c064="">FL</option>
                                                            <option value="GA" data-v-5e99c064="">GA</option>
                                                            <option value="HI" data-v-5e99c064="">HI</option>
                                                            <option value="ID" data-v-5e99c064="">ID</option>
                                                            <option value="IL" data-v-5e99c064="">IL</option>
                                                            <option value="IN" data-v-5e99c064="">IN</option>
                                                            <option value="IA" data-v-5e99c064="">IA</option>
                                                            <option value="KS" data-v-5e99c064="">KS</option>
                                                            <option value="KY" data-v-5e99c064="">KY</option>
                                                            <option value="LA" data-v-5e99c064="">LA</option>
                                                            <option value="ME" data-v-5e99c064="">ME</option>
                                                            <option value="MD" data-v-5e99c064="">MD</option>
                                                            <option value="MA" data-v-5e99c064="">MA</option>
                                                            <option value="MI" data-v-5e99c064="">MI</option>
                                                            <option value="MN" data-v-5e99c064="">MN</option>
                                                            <option value="MS" data-v-5e99c064="">MS</option>
                                                            <option value="MO" data-v-5e99c064="">MO</option>
                                                            <option value="MT" data-v-5e99c064="">MT</option>
                                                            <option value="NE" data-v-5e99c064="">NE</option>
                                                            <option value="NV" data-v-5e99c064="">NV</option>
                                                            <option value="NH" data-v-5e99c064="">NH</option>
                                                            <option value="NJ" data-v-5e99c064="">NJ</option>
                                                            <option value="NM" data-v-5e99c064="">NM</option>
                                                            <option value="NY" data-v-5e99c064="">NY</option>
                                                            <option value="NC" data-v-5e99c064="">NC</option>
                                                            <option value="ND" data-v-5e99c064="">ND</option>
                                                            <option value="OH" data-v-5e99c064="">OH</option>
                                                            <option value="OK" data-v-5e99c064="">OK</option>
                                                            <option value="OR" data-v-5e99c064="">OR</option>
                                                            <option value="PA" data-v-5e99c064="">PA</option>
                                                            <option value="PR" data-v-5e99c064="">PR</option>
                                                            <option value="RI" data-v-5e99c064="">RI</option>
                                                            <option value="SC" data-v-5e99c064="">SC</option>
                                                            <option value="SD" data-v-5e99c064="">SD</option>
                                                            <option value="TN" data-v-5e99c064="">TN</option>
                                                            <option value="TX" data-v-5e99c064="">TX</option>
                                                            <option value="UT" data-v-5e99c064="">UT</option>
                                                            <option value="VT" data-v-5e99c064="">VT</option>
                                                            <option value="VA" data-v-5e99c064="">VA</option>
                                                            <option value="WA" data-v-5e99c064="">WA</option>
                                                            <option value="WV" data-v-5e99c064="">WV</option>
                                                            <option value="WI" data-v-5e99c064="">WI</option>
                                                            <option value="WY" data-v-5e99c064="">WY</option>
                                                            <option value="AA" data-v-5e99c064="">AA</option>
                                                            <option value="AE" data-v-5e99c064="">AE</option>
                                                            <option value="AP" data-v-5e99c064="">AP</option>
                                                        </select></div>
                                                        <div class="base-select__spacer" data-v-5e99c064=""
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="pure-u-1 pure-u-md-6-24" data-v-927c11a4=""
                                                    >
                                                    <div id="" class="base-input__container" name="zip"
                                                         data-v-74b77462="" data-v-927c11a4="">
                                                        <div class="base-input" data-v-74b77462="">
                                                            <label class="base-input__label" for="" data-v-74b77462="">Zip
                                                                Code</label><input name="" type="text"
                                                                                   v-model="post.zip_code"
                                                                                   placeholder="Zip Code"
                                                                                   class="base-input__input"
                                                                                   tabindex="0" data-v-74b77462="">
                                                            <!--v-if--></div>
                                                        <div class="base-input__spacer" data-v-74b77462=""
                                                            >
                                                            <!--v-if--></div><!--v-if--></div>
                                                </div>
                                            </div>
                                        </fieldset>
                                        <fieldset class="data-v-927c11a4">
                                            <legend class="my-info__lowsight-legend data-v-927c11a4"> Address:</legend>

                                        </fieldset>

                                        <fieldset class="data-v-927c11a4">
                                            <legend class="my-info__lowsight-legend data-v-927c11a4"> Birthday:</legend>

                                            <div class="my-info__grid pure-g data-v-927c11a4">


                                                <div class="pure-u-1-3 pure-u-md-3-24 data-v-927c11a4"
                                                    >
                                                    <div id=""
                                                         class="base-select__container data-v-5e99c064 data-v-927c11a4"
                                                         name="birth_month">
                                                        <div class="base-select data-v-5e99c064">
                                                            <label class="base-select__label data-v-5e99c064"
                                                                   for="birth_day">Month</label>
                                                            <select name="month" v-model="month" id="month"
                                                                    class="base-select__select data-v-5e99c064"
                                                                    tabindex="0">
                                                                <option value="00" class="data-v-5e99c064"
                                                                        selected="selected" disabled hidden>{{arr1[1]}}
                                                                </option>
                                                                <option value="01" class="data-v-5e99c064">JANUARY
                                                                </option>
                                                                <option value="02" class="data-v-5e99c064">FEBRUARY
                                                                </option>
                                                                <option value="03" class="data-v-5e99c064">MARCH
                                                                </option>
                                                                <option value="04" class="data-v-5e99c064">APRIL
                                                                </option>
                                                                <option value="05" class="data-v-5e99c064">MAY</option>
                                                                <option value="06" class="data-v-5e99c064">JUNE</option>
                                                                <option value="07" class="data-v-5e99c064">JULY</option>
                                                                <option value="08" class="data-v-5e99c064">AUGUST
                                                                </option>
                                                                <option value="09" class="data-v-5e99c064">SEPTEMBER
                                                                </option>
                                                                <option value="10" class="data-v-5e99c064">OCTOBER
                                                                </option>
                                                                <option value="11" class="data-v-5e99c064">NOVEMBER
                                                                </option>
                                                                <option value="12" class="data-v-5e99c064">DECEMBER
                                                                </option>
                                                            </select></div>


                                                        <div class="base-select__spacer data-v-5e99c064"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>


                                                <div class="my-info__field--mid pure-u-6-24 pure-u-md-5-24 data-v-927c11a4"
                                                    >
                                                    <div id=""
                                                         class="base-select__container data-v-5e99c064 data-v-927c11a4"
                                                         name="birth_day">
                                                        <div class="base-select data-v-5e99c064">
                                                            <label class="base-select__label data-v-5e99c064"
                                                                   for="birth_day">Day</label>
                                                            <select name="day" v-model="day" id="day"
                                                                    class="base-select__select data-v-5e99c064 data-v-5e99c064"
                                                                    tabindex="0">
                                                                <option value="00" class="data-v-5e99c064"
                                                                        selected="selected" disabled hidden>{{arr1[2]}}
                                                                </option>
                                                                <option value="00" class="data-v-5e99c064"></option>
                                                                <option value="01" class="data-v-5e99c064">01</option>
                                                                <option value="02" class="data-v-5e99c064">02</option>
                                                                <option value="03" class="data-v-5e99c064">03</option>
                                                                <option value="04" class="data-v-5e99c064">04</option>
                                                                <option value="05" class="data-v-5e99c064">05</option>
                                                                <option value="06" class="data-v-5e99c064">06</option>
                                                                <option value="07" class="data-v-5e99c064">07</option>
                                                                <option value="08" class="data-v-5e99c064">08</option>
                                                                <option value="09" class="data-v-5e99c064">09</option>
                                                                <option value="10" class="data-v-5e99c064">10</option>
                                                                <option value="11" class="data-v-5e99c064">11</option>
                                                                <option value="12" class="data-v-5e99c064">12</option>
                                                                <option value="13" class="data-v-5e99c064">13</option>
                                                                <option value="14" class="data-v-5e99c064">14</option>
                                                                <option value="15" class="data-v-5e99c064">15</option>
                                                                <option value="16" class="data-v-5e99c064">16</option>
                                                                <option value="17" class="data-v-5e99c064">17</option>
                                                                <option value="18" class="data-v-5e99c064">18</option>
                                                                <option value="19" class="data-v-5e99c064">19</option>
                                                                <option value="20" class="data-v-5e99c064">20</option>
                                                                <option value="21" class="data-v-5e99c064">21</option>
                                                                <option value="22" class="data-v-5e99c064">22</option>
                                                                <option value="23" class="data-v-5e99c064">23</option>
                                                                <option value="24" class="data-v-5e99c064">24</option>
                                                                <option value="25" class="data-v-5e99c064">25</option>
                                                                <option value="26" class="data-v-5e99c064">26</option>
                                                                <option value="27" class="data-v-5e99c064">27</option>
                                                                <option value="28" class="data-v-5e99c064">28</option>
                                                                <option value="29" class="data-v-5e99c064">29</option>
                                                                <option value="30" class="data-v-5e99c064">30</option>
                                                                <option value="31" class="data-v-5e99c064">31</option>
                                                            </select></div>
                                                        <div class="base-select__spacer data-v-5e99c064"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>

                                                <div class="my-info__field pure-u-9-24 pure-u-md-5-24"
                                                     data-v-927c11a4="">
                                                    <div id=""
                                                         class="base-select__container data-v-5e99c064 data-v-927c11a4"
                                                         name="birth_year">
                                                        <div class="base-select data-v-5e99c064">
                                                            <label class="base-select__label data-v-5e99c064"
                                                                   for="birth_year">Year</label>
                                                            <select v-model="year" name="year" id="year"
                                                                    class="base-select__select data-v-5e99c064"
                                                                    tabindex="0" selected="selected">
                                                                <option value="0000" class="data-v-5e99c064" selected
                                                                        hidden>{{arr1[0]}}
                                                                </option>
                                                                <option value="0000" class="data-v-5e99c064"></option>
                                                                <option value="2022" class="data-v-5e99c064">2022
                                                                </option>
                                                                <option value="2021" class="data-v-5e99c064">2021
                                                                </option>
                                                                <option value="2020" class="data-v-5e99c064">2020
                                                                </option>
                                                                <option value="2019" class="data-v-5e99c064">2019
                                                                </option>
                                                                <option value="2018" class="data-v-5e99c064">2018
                                                                </option>
                                                                <option value="2017" class="data-v-5e99c064">2017
                                                                </option>
                                                                <option value="2016" class="data-v-5e99c064">2016
                                                                </option>
                                                                <option value="2015" class="data-v-5e99c064">2015
                                                                </option>
                                                                <option value="2014" class="data-v-5e99c064">2014
                                                                </option>
                                                                <option value="2013" class="data-v-5e99c064">2013
                                                                </option>
                                                                <option value="2012" class="data-v-5e99c064">2012
                                                                </option>
                                                                <option value="2011" class="data-v-5e99c064">2011
                                                                </option>
                                                                <option value="2010" class="data-v-5e99c064">2010
                                                                </option>
                                                                <option value="2009" class="data-v-5e99c064">2009
                                                                </option>
                                                                <option value="2008" class="data-v-5e99c064">2008
                                                                </option>
                                                                <option value="2007" class="data-v-5e99c064">2007
                                                                </option>
                                                                <option value="2006" class="data-v-5e99c064">2006
                                                                </option>
                                                                <option value="2005" class="data-v-5e99c064">2005
                                                                </option>
                                                                <option value="2004" class="data-v-5e99c064">2004
                                                                </option>
                                                                <option value="2003" class="data-v-5e99c064">2003
                                                                </option>
                                                                <option value="2002" class="data-v-5e99c064">2002
                                                                </option>
                                                                <option value="2001" class="data-v-5e99c064">2001
                                                                </option>
                                                                <option value="2000" class="data-v-5e99c064">2000
                                                                </option>
                                                                <option value="1999" class="data-v-5e99c064">1999
                                                                </option>
                                                                <option value="1998" class="data-v-5e99c064">1998
                                                                </option>
                                                                <option value="1997" class="data-v-5e99c064">1997
                                                                </option>
                                                                <option value="1996" class="data-v-5e99c064">1996
                                                                </option>
                                                                <option value="1995" class="data-v-5e99c064">1995
                                                                </option>
                                                                <option value="1994" class="data-v-5e99c064">1994
                                                                </option>
                                                                <option value="1993" class="data-v-5e99c064">1993
                                                                </option>
                                                                <option value="1992" class="data-v-5e99c064">1992
                                                                </option>
                                                                <option value="1991" class="data-v-5e99c064">1991
                                                                </option>
                                                                <option value="1990" class="data-v-5e99c064">1990
                                                                </option>
                                                                <option value="1989" class="data-v-5e99c064">1989
                                                                </option>
                                                                <option value="1988" class="data-v-5e99c064">1988
                                                                </option>
                                                                <option value="1987" class="data-v-5e99c064">1987
                                                                </option>
                                                                <option value="1986" class="data-v-5e99c064">1986
                                                                </option>
                                                                <option value="1985" class="data-v-5e99c064">1985
                                                                </option>
                                                                <option value="1984" class="data-v-5e99c064">1984
                                                                </option>
                                                                <option value="1983" class="data-v-5e99c064">1983
                                                                </option>
                                                                <option value="1982" class="data-v-5e99c064">1982
                                                                </option>
                                                                <option value="1981" class="data-v-5e99c064">1981
                                                                </option>
                                                                <option value="1980" class="data-v-5e99c064">1980
                                                                </option>
                                                                <option value="1979" class="data-v-5e99c064">1979
                                                                </option>
                                                                <option value="1978" class="data-v-5e99c064">1978
                                                                </option>
                                                                <option value="1977" class="data-v-5e99c064">1977
                                                                </option>
                                                                <option value="1976" class="data-v-5e99c064">1976
                                                                </option>
                                                                <option value="1975" class="data-v-5e99c064">1975
                                                                </option>
                                                                <option value="1974" class="data-v-5e99c064">1974
                                                                </option>
                                                                <option value="1973" class="data-v-5e99c064">1973
                                                                </option>
                                                                <option value="1972" class="data-v-5e99c064">1972
                                                                </option>
                                                                <option value="1971" class="data-v-5e99c064">1971
                                                                </option>
                                                                <option value="1970" class="data-v-5e99c064">1970
                                                                </option>
                                                                <option value="1969" class="data-v-5e99c064">1969
                                                                </option>
                                                                <option value="1968" class="data-v-5e99c064">1968
                                                                </option>
                                                                <option value="1967" class="data-v-5e99c064">1967
                                                                </option>
                                                                <option value="1966" class="data-v-5e99c064">1966
                                                                </option>
                                                                <option value="1965" class="data-v-5e99c064">1965
                                                                </option>
                                                                <option value="1964" class="data-v-5e99c064">1964
                                                                </option>
                                                                <option value="1963" class="data-v-5e99c064">1963
                                                                </option>
                                                                <option value="1962" class="data-v-5e99c064">1962
                                                                </option>
                                                                <option value="1961" class="data-v-5e99c064">1961
                                                                </option>
                                                                <option value="1960" class="data-v-5e99c064">1960
                                                                </option>
                                                                <option value="1959" class="data-v-5e99c064">1959
                                                                </option>
                                                                <option value="1958" class="data-v-5e99c064">1958
                                                                </option>
                                                                <option value="1957" class="data-v-5e99c064">1957
                                                                </option>
                                                                <option value="1956" class="data-v-5e99c064">1956
                                                                </option>
                                                                <option value="1955" class="data-v-5e99c064">1955
                                                                </option>
                                                                <option value="1954" class="data-v-5e99c064">1954
                                                                </option>
                                                                <option value="1953" class="data-v-5e99c064">1953
                                                                </option>
                                                                <option value="1952" class="data-v-5e99c064">1952
                                                                </option>
                                                                <option value="1951" class="data-v-5e99c064">1951
                                                                </option>
                                                                <option value="1950" class="data-v-5e99c064">1950
                                                                </option>
                                                                <option value="1949" class="data-v-5e99c064">1949
                                                                </option>
                                                                <option value="1948" class="data-v-5e99c064">1948
                                                                </option>
                                                                <option value="1947" class="data-v-5e99c064">1947
                                                                </option>
                                                                <option value="1946" class="data-v-5e99c064">1946
                                                                </option>
                                                                <option value="1945" class="data-v-5e99c064">1945
                                                                </option>
                                                                <option value="1944" class="data-v-5e99c064">1944
                                                                </option>
                                                                <option value="1943" class="data-v-5e99c064">1943
                                                                </option>
                                                                <option value="1942" class="data-v-5e99c064">1942
                                                                </option>
                                                                <option value="1941" class="data-v-5e99c064">1941
                                                                </option>
                                                                <option value="1940" class="data-v-5e99c064">1940
                                                                </option>
                                                                <option value="1939" class="data-v-5e99c064">1939
                                                                </option>
                                                                <option value="1938" class="data-v-5e99c064">1938
                                                                </option>
                                                                <option value="1937" class="data-v-5e99c064">1937
                                                                </option>
                                                                <option value="1936" class="data-v-5e99c064">1936
                                                                </option>
                                                                <option value="1935" class="data-v-5e99c064">1935
                                                                </option>
                                                                <option value="1934" class="data-v-5e99c064">1934
                                                                </option>
                                                                <option value="1933" class="data-v-5e99c064">1933
                                                                </option>
                                                                <option value="1932" class="data-v-5e99c064">1932
                                                                </option>
                                                                <option value="1931" class="data-v-5e99c064">1931
                                                                </option>
                                                                <option value="1930" class="data-v-5e99c064">1930
                                                                </option>
                                                                <option value="1929" class="data-v-5e99c064">1929
                                                                </option>
                                                                <option value="1928" class="data-v-5e99c064">1928
                                                                </option>
                                                                <option value="1927" class="data-v-5e99c064">1927
                                                                </option>
                                                                <option value="1926" class="data-v-5e99c064">1926
                                                                </option>
                                                                <option value="1925" class="data-v-5e99c064">1925
                                                                </option>
                                                                <option value="1924" class="data-v-5e99c064">1924
                                                                </option>
                                                                <option value="1923" class="data-v-5e99c064">1923
                                                                </option>
                                                                <option value="1922" class="data-v-5e99c064">1922
                                                                </option>
                                                            </select></div>
                                                        <div class="base-select__spacer data-v-5e99c064"
                                                            ><!--v-if--></div><!--v-if--></div>
                                                </div>
                                                <div class="my-info__field--end pure-u-1 pure-u-md-9-24"
                                                     data-v-927c11a4=""><!-- <base-input

            /> --></div>
                                            </div>
                                        </fieldset>
                                        <button tabindex="0"
                                                class="base-button my-info__submit data-v-5d25a20e data-v-927c11a4"
                                                href="" aria-disabled="true" data-v-5d25a20e="" data-v-927c11a4="">
                                            update
                                        </button>

                                    </form>
                                </section>
                            </main>
                        </main>
                    </div>
                </section>
            </div>
        </section>
        <section class="data-v-8f1b00a4 data-v-51db9a34">
            <header class="data-v-8f1b00a4"><h2 class="data-v-8f1b00a4">My History</h2>
                <!--v-if--></header>
            <div class="content data-v-ae1b3624 data-v-51db9a34" id="token-activity">
                <header class="data-v-ae1b3624">
                    <header class="token-activity data-v-51db9a34">
                        <h2 class="data-v-51db9a34">Token Activity</h2>
                        <div class="token-balance-container data-v-14d74b8e data-v-51db9a34">
                            <div class="token-balance data-v-14d74b8e">
                                <span class="data-v-14d74b8e">Current Balance:</span>
                                <div class="autocounter data-v-14d74b8e">
                                    <span class="data-v-14d74b8e">{{post.quota_tokens}}</span></div>
                            </div>
                            <button tabindex="0"
                                    class="base-button base-button--enabled redeem data-v-5d25a20e data-v-14d74b8e"
                                    type="button" aria-disabled="false">Redeem
                            </button>
                        </div>
                    </header>
                    <span class="data-v-ae1b3624">
                                <span class="arrow data-v-ae1b3624"></span></span></header>
                <main class="data-v-ae1b3624">
                    <main class="token-activity-container data-v-51db9a34">
                        <div class="token-balance-container data-v-14d74b8e data-v-51db9a34">
                            <div class="token-balance data-v-14d74b8e">
                                <span class="data-v-14d74b8e">Current Balance:</span>
                                <div class="autocounter data-v-14d74b8e">
                                    <span class="data-v-14d74b8e">{{post.quota_tokens}}</span></div>
                            </div>
                            <button tabindex="0"
                                    class="base-button base-button--enabled redeem data-v-5d25a20e data-v-14d74b8e"
                                    type="button" aria-disabled="false">Redeem
                            </button>
                        </div>
                        <ul class="token-history infinite-scroll data-v-6e48d68e">
                            <li class="data-v-6e48d68e" v-for="activity in activities">
                                <div class="token-history-item data-v-49ec0941 data-v-6e48d68e">
                                    <div class="row date-device data-v-49ec0941">
                                        <span class="date data-v-49ec0941">{{ activity.created_at| formatDate }}</span>
                                        <!--v-if-->
                                        <div class="device desktop"></div>
                                        <!--v-if--></div>
                                    <div class="row token-reward data-v-49ec0941">
                                        <span class="reward-description data-v-49ec0941">{{activity.description}}!</span>
                                        <div v-if="activity.token>0">
                                            <span class="token-amount data-v-49ec0941">{{activity.token}}</span>
                                        </div>
                                        <div v-else>
                                            <span class="token-amount data-v-49ec0941">{{activity.token}}</span>
                                        </div>
                                    </div>
                                    <div class="row token-balance data-v-49ec0941">
                                        <span class="data-v-49ec0941">TOKEN BALANCE:</span>
                                        <span class="token-balance-amount data-v-49ec0941">{{activity.somme_token}}</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </main>
                </main>
            </div>
        </section>
    </article>
</template>

<script>

    export default {

        data() {
            return {
                post: {},
                arr2: {},
                arr1: [],
                day: '',
                month: '',
                year: '',
                msg: [],
                activities: [],
                arrayActivity: [],
                sum: 0,
            }
        },
        computed: {},
        created() {
            this.axios
                .get(window.location.origin + '/account/edit')
                .then((response) => {
                    this.post = response.data;
                    this.arr1 = this.post.birth_date.split('-');
                    this.year = this.post.birth_date.split('-')[0];
                    this.day = this.post.birth_date.split('-')[2];
                    this.month = this.post.birth_date.split('-')[1];

                });
            this.axios
                .get(window.location.origin + '/account/getLogActivities')
                .then((response) => {
                    this.activities = response.data;
                    Array.from(this.activities).forEach(element =>
                        (element['somme_token'] = this.sum += parseInt(element.token)),
                    );

                });
        },
        methods: {
            onChange(event) {
                this.selectedMonth = event.target.value;
            },
            updatePost() {
                this.post.birth_date = this.year + '-' + this.month + '-' + this.day;
                this.axios
                    .post(window.location.origin + '/account/update', this.post)
                    .then((response) => {

                        this.$alert("Account Updated Successfully !");


                    });
            }
        }
    }
</script>
