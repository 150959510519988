<template>
    <nav>
        <dropdown title="Redeem Tokens"  />
    </nav>
</template>

<script>
import Dropdown from './Dropdown';
export default {
    name: 'navbar',
    components: {
        Dropdown
    },
    data () {
        return {

        }
    }
}
</script>

<style>
nav {
    display: flex;
    align-items: center;
    justify-content: center;
}

nav .menu-item.active,
nav .menu-item:hover {
    background-color: #444;
}

nav .menu-item .sub-menu {
    position: absolute;
    background-color: #222;
    top: calc(100% + 12px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 0px 0px 16px 16px;
}

</style>
