export default {

    data() {
        return {
            tokens_quota: window.ThePsh.tokens_quota
        };
    },

    computed: {

    },

    watch: {

    },

    mounted: function () {
        this.getTokensQuota();
        this.$nextTick(function() {
            if (typeof window.addEventListener !== 'undefined') {
                window.addEventListener('message', this.receiveMessage, false);

            } else if (typeof window.attachEvent !== 'undefined') {

                window.attachEvent('onmessage', this.receiveMessage);
            }
        })
    },

    methods: {
        receiveMessage (event) {
            if (event.data.event_id === 'game_event'){
                this.getTokensQuota();
                if (event.data.game_type === 'lotto'){
                    this.tokensLottoNotification();
                    /*if (window.ThePsh.loggedIn){
                        this.tokensLottoNotification();
                        /!*window.location.reload();*!/
                    }else{
                        window.location.href = route('official-registration');
                    }*/
                }else if(event.data.game_type === 'search'){
                    this.tokensSearchNotification();
                }else {
                    this.tokensNotification();
                }
                this.showCelebrateLogo();
            }
        },
        getTokensQuota () {
            fetch(route('games.get-score',window.ThePsh.user))
                .then(response => response.json())
                .then(data => {
                    if (data.score !== 0){
                        this.tokens_quota = data.score;
                    }
                });
        },
        tokensNotificationV1 () {
            let position = 'top-end';
            let mobileClass = 'size-desktop-img';

            if (ThePsh.is_mobile === "1"){
                position = 'center';
                mobileClass  = 'size-mobile-img'
            }
            this.$fire({
                position: position,
                imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                animation: false,
                showConfirmButton:false,
                showCancelButton: false,
                cancelButtonText:
                    '<i class="fa fa-thumbs-up"></i> Keep playing!',
                confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                confirmButtonColor: '#f9791d',
                cancelButtonColor: '#f4b007',
                customClass: {
                    popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                    image: mobileClass,
                },
                html: `
                  <div class="celebrate-actions">
                    <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep playing!</i></button>
                    <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateBtnClicked('next')"><i>Next game!</i></button>
                    <button type="button" class="swal2-deny swal2-styled" onclick="onCelebrateBtnClicked('other')"><i>Other games!</i></button>
                  </div>`
            });
        },
        tokensNotification () {
            let position = 'center';
            let mobileClass = 'size-desktop-img';

            if (ThePsh.is_mobile === "1"){
                position = 'center';
                mobileClass  = 'size-mobile-img'
            }
            this.$fire({
                title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                icon: 'info',
                position: position,
                // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                animation: false,
                showConfirmButton:false,
                showCancelButton: false,
                cancelButtonText:
                    '<i class="fa fa-thumbs-up"></i> Keep playing!',
                confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                confirmButtonColor: '#f9791d',
                cancelButtonColor: '#f4b007',
                customClass: {
                    popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                    image: mobileClass,
                },
                html: ` You have earned new tokens. Try to <a href="`+route('login')+`">Sign in</a> to submit your tokens and <a href="javascript:;">win Super prize</a>.'
                      <div class="celebrate-actions mt-4">
                        <button type="button" class="swal2-info swal2-styled" onclick="onCelebrateBtnClicked('signin')"><i>Sign in!</i></button>
                        <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep playing!</i></button>
                        <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateBtnClicked('other')"><i>Other games!</i></button>
                      </div>
                    `
            });
        },
        tokensLottoNotification () {
            let position = 'center';
            let mobileClass = 'size-desktop-img';

            if (ThePsh.is_mobile === "1"){
                position = 'center';
                mobileClass  = 'size-mobile-img'
            }
            this.$fire({
                title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                icon: 'info',
                position: position,
                // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                animation: false,
                showConfirmButton:false,
                showCancelButton: false,
                cancelButtonText:
                    '<i class="fa fa-thumbs-up"></i> Keep playing!',
                confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                confirmButtonColor: '#f9791d',
                cancelButtonColor: '#f4b007',
                customClass: {
                    popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                    image: mobileClass,
                },
                html: ` You have earned new tokens. Try to <a href="`+route('login')+`">Sign in</a> to submit your tokens and <a href="javascript:;">win Super prize</a>.'
                  <div class="celebrate-actions mt-4">
                    <button type="button" class="swal2-info swal2-styled" onclick="onCelebrateBtnClicked('signin')"><i>Sign in!</i></button>
                    <button type="button" class="swal2-confirm swal2-styled" onclick="onCelebrateBtnClicked('other')"><i>Other games!</i></button>
                  </div>`
            });
        },
        tokensSearchNotification () {
            let position = 'center';
            let mobileClass = 'size-desktop-img';

            if (ThePsh.is_mobile === "1"){
                position = 'center';
                mobileClass  = 'size-mobile-img'
            }
            this.$fire({
                title: '<strong  class="celebrate-icon">Congratulations!</strong>',
                icon: 'info',
                position: position,
                // imageUrl: ThePsh.baseUrl+'/images/main/congratulation.gif',
                animation: false,
                showConfirmButton:false,
                showCancelButton: false,
                cancelButtonText:
                    '<i class="fa fa-thumbs-up"></i> Keep searching!',
                confirmButtonText: '<i class="fa fa-thumbs-up"> Next Game!</i>',
                confirmButtonColor: '#f9791d',
                cancelButtonColor: '#f4b007',
                customClass: {
                    popup: 'celebrate-tokens animate__animated animate__fadeInDown',
                    image: mobileClass,
                },
                html: ` You have earned new tokens. Try to <a href="`+route('login')+`">Sign in</a> to submit your tokens and <a href="javascript:;">win Super prize</a>.'
                  <div class="celebrate-actions mt-4">
                    <button type="button" class="swal2-info swal2-styled" onclick="onCelebrateBtnClicked('signin')"><i>Sign in!</i></button>
                    <button type="button" class="swal2-cancel swal2-styled" onclick="onCelebrateBtnClicked('keep')"><i>Keep searching!</i></button>
                  </div>`
            });
        },
        showCelebrateLogo () {
            $('.uninav__token-balance__amount').addClass('celebrate');
        },
    },
    beforeDestroy: {
        removeCallbackTokensQuota () {
            if (typeof window.addEventListener !== 'undefined') {
                window.removeEventListener('message', this.receiveMessage)

            } else if (typeof window.attachEvent !== 'undefined') {
                window.removeEventListener('message', this.receiveMessage)
            }
        }
    }
}