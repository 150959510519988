<template>
    <div class="content" bis_skin_checked="1">
        <div id="app" class="data-v-app" bis_skin_checked="1">
            <header class="data-v-201d486e data-v-2a28249e">
                <h1 class="data-v-201d486" style="max-width: 628px;">My PSH Account</h1><!--v-if--></header>
            <section class="data-v-8f1b00a4 data-v-2a28249e" style="max-width: 628px;">
                <header class="data-v-8f1b00a4">
                    <h2 class="data-v-8f1b00a4" style="max-width: 628px;font-weight: 500">Change Email</h2>
                    <div class="data-v-8f1b00a4" style="max-width: 628px;" bis_skin_checked="1">
                        <a class="exitModal data-v-8f1b00a4"></a></div><!--v-if--></header><div class="content data-v-ae1b3624 data-v-2a28249e" bis_skin_checked="1"><header class="hideDesktopHeader data-v-ae1b3624">
                <h2 class="data-v-2a28249e">Change Email</h2>
                <span class="data-v-ae1b3624"><!--v-if--></span></header>
                <main class="data-v-ae1b3624"><div class="data-v-2a28249e" bis_skin_checked="1">
                    <p class="top-message data-v-2a28249e"><h3>New email address is required</h3></p>
                    <p class="top-message data-v-2a28249e">Please enter your <b>new</b> email address. Thank&nbsp;you.</p></div>
                    <form class="pure-form pure-form-stacked data-v-2a28249e"  @submit="checkForm" >
                        <fieldset class="data-v-2a28249e">
                    <div id="" class="base-input__container data-v-74b7746 data-v-2a28249e" maxlength="50"  bis_skin_checked="1"><div class="base-input data-v-74b77462" bis_skin_checked="1">
                        <label class="base-input__label data-v-74b77462" for="u_em" >Email</label>
                        <input type="email" id="input-email" name="email" placeholder="Email"
                               required="" inputmode="email" v-model="email" data-validationmessage="Please enter a valid email"
                               maxlength="50"  class="base-input__input data-v-74b77462" tabindex="0" >
                        <span class="floating-placeholder" v-if="msg.email">{{msg.email}}</span>

                        <!--v-if--></div>

                        <div class="base-input__spacer data-v-74b77462" bis_skin_checked="1"><!--v-if--></div><!--v-if--></div>
                        <div id="" class="base-input__container data-v-74b77462 data-v-2a28249e" maxlength="50"  bis_skin_checked="1">
                            <div class="base-input data-v-74b77462" bis_skin_checked="1">
                                <label class="base-input__label data-v-74b77462" for="confirm_email" >Confirm Email</label>
                            <input
                                type="email" id="input-confirm_email" name="confirm_email"
                                placeholder="Confirm Email" v-model="confirm_email" required="" inputmode="email"
                                data-validationmessage="Please enter a valid email"
                                maxlength="50"  class="base-input__input data-v-74b77462" tabindex="0" ><!--v-if--></div><!--v-if--></div></fieldset><ul class="general-error-list data-v-2a28249e"></ul><!--v-if--><div class="form-content data-v-2a28249e" bis_skin_checked="1"><!--v-if--><!--v-if--><p class="data-v-2a28249e"><!--v-if--></p><div id="" class="base-input__container data-v-74b77462 data-v-2a28249e" bis_skin_checked="1"><div class="base-input signInBtn data-v-74b77466" bis_skin_checked="1"><!--v-if-->
                        <span class="floating-placeholder" v-if="this.msg.confirm_email">{{this.msg.confirm_email}}</span>
                    </div>
                        <input  id="" type="submit" name="" placeholder="" class="pure-button pure-button-primary button-xxlarge data-v-74b77463" tabindex="0" value="Confirm" ><!--v-if--></div><div class="false  data-v-74b77462" bis_skin_checked="1"><!--v-if--></div></div></form></main></div></section>
        <div class="modals-container data-v-2a28249e" bis_skin_checked="1"></div></div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            email: null,
            confirm_email: null,
            msg: [],
            formValidated: true,
        };
    },
    computed: {

    },
    methods:{
        checkForm(e) {
            this.msg = [];
            this.formValidated = true;
            if (! this.email || this.email === 'null') {
                this.msg['email'] = 'Email required';
                this.formValidated = false;
            } else if (! this.validateEmail(this.email)) {
                this.msg['email'] = 'Please provide a valid email';
                this.formValidated = false;
            }
            if (! this.confirm_email || this.confirm_email === 'null') {
                this.msg['confirm_email'] = 'Email Confirm required';
                this.formValidated = false;
            } else if (! this.confirmationEmail(this.email,this.confirm_email)) {
                this.msg['confirm_email'] = 'Confirm Email and Email do not match.';
                this.formValidated = false;
            }
            if (this.formValidated) {
                this.formSubmit();
                this.$alert("Email Updated Successfully !");
            } else {

                e.preventDefault();
            }
            return false;
        },
        validateEmail(email) {
            var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(email);
        },
        confirmationEmail(email,confirm_email) {

            return (email === confirm_email);
        },
        async formSubmit() {
            var vm = this;
            await $.ajax({
                method: 'GET',
                url: route('account.new'),
                data: {
                    email: this.email,
                },
            }).done((response) => {
                if (response.code === 400) {
                    vm.msg['error'] = response.message;
                } else {
                }
                vm.$forceUpdate();
            }).fail((xhr) => {
                var errors = xhr.responseJSON.errors;
                for (const [key, value] of Object.entries(errors)) {
                    vm.msg[key] = value[0];
                }
                vm.$forceUpdate();
            });
        },
    },
    mounted() {
    }}
</script>
