<template>
    <form data-name="main" id="input-form" @submit="checkForm" method="post" action="javascript:;" novalidate="true">
        <section id="input-area" class="step-transition--slide">
        <div class="input-page step--fullreg" style="">
            <div class="input-body">
                <div class="comp comp-text themed--callout">
                    <div class="svelte-13yf3zx">
                        <div class="text svelte-13yf3zx">It's FREE - Enter Now!
                        </div>
                    </div>
                </div>
                <div class="comp comp-text small-font left">
                    <div class="svelte-13yf3zx left">
                        <div class="text svelte-13yf3zx">
                            <p>Complete this
                                Official Entry Registration form by the deadline
                                posted in the <a target="_blank"
                                                 href="#">Official
                                    Rules</a> and submit below.</p>
                        </div>
                        <span class="floating-placeholder" v-if="msg.error">{{msg.error}}</span>
                    </div>
                </div>
                <div class="comp comp-name medium-font themed--user-name">
                    <div class="title svelte-ezy6tt">
                        <div class="formgroup  reg-input-select  svelte-l1jf1t">
                            <label for="input-title" class="svelte-l1jf1t">Title:</label>
                            <div class="forminput svelte-l1jf1t">
                                <select id="input-title" name="title"
                                        placeholder="Title" minlength="2"
                                        maxlength="50" required=""
                                        class="svelte-l1jf1t" v-model="title">
                                    <option class="placeholder svelte-l1jf1t">Select
                                    </option>
                                    <option value="Mr.">Mr.</option>
                                    <option value="Mrs.">Mrs.</option>
                                    <option value="Ms.">Ms.</option>
                                </select>
                                <span class="floating-placeholder" v-if="msg.title">{{msg.title}}</span>
                                <div class="msg-container svelte-zvjdef"></div>
                            </div>
                        </div>
                    </div>
                    <div class="name svelte-ezy6tt">
                        <div class="fname svelte-ezy6tt">
                            <div class="formgroup  reg-input-text  svelte-l1jf1t">
                                <label for="input-first_name"
                                       class="svelte-l1jf1t">First Name:</label>
                                <div class="forminput svelte-l1jf1t">
                                    <input v-model="first_name"
                                        type="text" id="input-first_name"
                                        name="first_name"
                                        placeholder="First Name"
                                        minlength="2" maxlength="50"
                                        required="" class="svelte-l1jf1t">
                                    <span class="floating-placeholder" v-if="msg.first_name">{{msg.first_name}}</span>
                                    <div class="msg-container svelte-zvjdef"></div>
                                </div>
                            </div>
                        </div>
                        <div class="lname svelte-ezy6tt">
                            <div class="formgroup  reg-input-text  svelte-l1jf1t">
                                <label for="input-last_name"
                                       class="svelte-l1jf1t">Last Name:</label>
                                <div class="forminput svelte-l1jf1t">
                                    <input v-model="last_name"
                                        type="text" id="input-last_name"
                                        name="last_name"
                                        placeholder="Last Name"
                                        minlength="2" maxlength="50"
                                        required="" class="svelte-l1jf1t">
                                    <span class="floating-placeholder" v-if="msg.last_name">{{msg.last_name}}</span>
                                    <div class="msg-container svelte-zvjdef"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comp comp-address ">
                    <div class="formgroup  reg-input-text  svelte-l1jf1t">
                        <label for="input-address" class="svelte-l1jf1t">Street
                            Address:</label>
                        <div class="forminput svelte-l1jf1t">
                            <input v-model="address" type="text" id="input-address" name="address" placeholder="Street Address"
                                   minlength="2" maxlength="64" required="" class="svelte-l1jf1t">
                            <span class="floating-placeholder" v-if="msg.address">{{msg.address}}</span>
                            <div class="msg-container svelte-zvjdef"></div>
                        </div>
                    </div>
                </div>
                <div class="com com-state">
                    <div class="address svelte-ezy6tt">
                        <div class="app_suite svelte-1kooqsj">
                            <div class="formgroup  reg-input-text  svelte-l1jf1t">
                                <label for="input-apt_suite" class="svelte-l1jf1t">Apt
                                    / Suite:</label>
                                <div class="forminput svelte-l1jf1t">
                                    <input v-model="apt_suite" type="text" id="input-apt_suite" name="apt_suite"
                                           placeholder="Apt / Suite" class="svelte-l1jf1t">
                                    <span class="floating-placeholder" v-if="msg.apt_suite">{{msg.apt_suite}}</span>
                                    <div class="msg-container svelte-zvjdef"></div>
                                </div>
                            </div>
                        </div>
                        <div class="nstate svelte-1kooqsj">
                            <div class="formgroup  reg-input-text  svelte-l1jf1t">
                                <label for="input-state" class="svelte-l1jf1t">State:</label>
                                <div class="forminput svelte-l1jf1t">
                                    <input v-model="state" type="text" id="input-state" name="state"
                                           placeholder="State" class="svelte-l1jf1t">
                                    <span class="floating-placeholder" v-if="msg.state">{{msg.state}}</span>
                                    <div class="msg-container svelte-zvjdef"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comp comp-citystatezip ">
                    <div class="state svelte-ezy6tt">
                        <div class="statezip  svelte-14gq2lh">
                            <div class="zipcontainer svelte-14gq2lh">
                                <div class="formgroup  reg-input-zip  svelte-l1jf1t">
                                    <label for="input-zip" class="svelte-l1jf1t">Zip
                                        Code:</label>
                                    <div class="forminput svelte-l1jf1t">
                                        <input v-model="zip_code" type="text" id="input-zip" name="zip_code" placeholder="Zip Code"
                                               minlength="2" maxlength="5" required="" inputmode="numeric"
                                               pattern="[0-9]*" class="svelte-l1jf1t">
                                        <span class="floating-placeholder" v-if="msg.zip_code">{{msg.zip_code}}</span>
                                        <div class="msg-container svelte-zvjdef"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="statecity  svelte-14gq2lh">
                            <div class="zipcontainer svelte-14gq2lh">
                                <div class="formgroup  reg-input-zip  svelte-l1jf1t">
                                    <label for="input-city" class="svelte-l1jf1t">city:</label>
                                    <div class="forminput svelte-l1jf1t">
                                        <input v-model="city" type="text" id="input-city" name="city" placeholder="City"
                                               required="" inputmode="numeric" class="svelte-l1jf1t">
                                        <span class="floating-placeholder" v-if="msg.city">{{msg.city}}</span>
                                        <div class="msg-container svelte-zvjdef"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comp comp-email ">
                    <div class="formgroup  reg-input-email  svelte-l1jf1t">
                        <label for="input-email"
                               class="svelte-l1jf1t">Email:</label>
                        <div class="forminput svelte-l1jf1t">
                            <input type="email" id="input-email" name="email" placeholder="Email"
                                   required="" inputmode="email" v-model="email" data-validationmessage="Please enter a valid email"
                                   class="svelte-l1jf1t" >
                            <span class="floating-placeholder" v-if="msg.email">{{msg.email}}</span>
                            <div class="msg-container svelte-zvjdef"></div>
                        </div>
                    </div>
                    <div class="formgroup  reg-input-email  svelte-l1jf1t">
                        <label for="input-confirm_email" class="svelte-l1jf1t">Confirm
                            Email:</label>
                        <div class="forminput svelte-l1jf1t">
                            <input type="email" id="input-confirm_email" name="confirm_email"
                                   placeholder="Confirm Email" v-model="confirm_email" required="" inputmode="email"
                                   data-validationmessage="Please enter a valid email"
                                   class="svelte-l1jf1t">
                            <span class="floating-placeholder" v-if="this.msg.confirm_email">{{this.msg.confirm_email}}</span>
                            <div class="msg-container svelte-zvjdef"></div>
                        </div>
                    </div>
                </div>
                <div class="comp comp-dateofbirth ">
                    <div class="formgroup  svelte-gzith5">
                        <div class="forminput svelte-gzith5">
                            <strong class="mainlabel svelte-gzith5">Date of
                                Birth:</strong>
                            <div class="inputgroup svelte-gzith5">
                                <input data-validationmessage="Please select a valid date of birth"
                                       pattern="[0-9]{2}/[0-9]{2}/[0-9]{4}" type="text" name="dob"
                                       style="display: none;">
                                <div class="formgroup  reg-input-select  svelte-l1jf1t">
                                    <label for="input-birth_month"
                                           class="svelte-l1jf1t">Month:</label>
                                    <div class="forminput svelte-l1jf1t">
                                        <select v-model="birth_month" id="input-birth_month" name="birth_month" placeholder="Month" @change="onChange($event)"
                                                class="svelte-l1jf1t">
                                            <option class="placeholder svelte-l1jf1t"
                                                    value="">Month
                                            </option>
                                            <option v-for="monthV in this.months" :value="monthV.month" >{{ monthV.month }}</option>

                                        </select>
                                        <span class="floating-placeholder" v-if="msg.birth_month">{{msg.birth_month}}</span>
                                        <div class="msg-container svelte-zvjdef"></div>
                                    </div>
                                </div>
                                <div class="formgroup  reg-input-select  svelte-l1jf1t">
                                    <label for="input-birth_day"
                                           class="svelte-l1jf1t">Day:</label>
                                    <div class="forminput svelte-l1jf1t">
                                        <select v-model="birth_day" id="input-birth_day" name="birth_day" placeholder="Day"
                                                class="svelte-l1jf1t">
                                            <option class="placeholder svelte-l1jf1t"
                                                    value="">Day
                                            </option>
                                            <option v-for="day in filterDays[0].days" :value="day">{{ day }}</option>
                                        </select>
                                        <span class="floating-placeholder" v-if="msg.birth_day">{{msg.birth_day}}</span>
                                        <div class="msg-container svelte-zvjdef"></div>
                                    </div>
                                </div>
                                <div class="formgroup  reg-input-select  svelte-l1jf1t">
                                    <label for="input-birth_year"
                                           class="svelte-l1jf1t">Year:</label>
                                    <div class="forminput svelte-l1jf1t">
                                        <select v-model="birth_year" id="input-birth_year" name="birth_year" placeholder="Year"
                                                class="svelte-l1jf1t">
                                            <option class="placeholder svelte-l1jf1t"
                                                    value="">Year
                                            </option>
                                            <option v-for="year in filterYears" :value="year">{{ year }}</option>
                                        </select>
                                        <span class="floating-placeholder" v-if="msg.birth_year">{{msg.birth_year}}</span>
                                        <div class="msg-container svelte-zvjdef"></div>
                                    </div>
                                </div>
                            </div>
                            <div class="dob-error svelte-gzith5">
                                <div class="msg-container svelte-zvjdef"></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="comp comp-checkbox ">
                    <div class="checkbox-area svelte-1mzq9pz">
                        <label class="checkbox checkbox--remembered svelte-1mzq9pz"
                               for="checkbox-remembered">
                            <input type="checkbox" id="checkbox-remembered" name="remembered"
                                   value="true" class="svelte-1mzq9pz">
                            REMEMBER ME</label>
                    </div>
                </div>
            </div>
        </div>
        <input type="submit" class="hidden" id="signup-winit"/>
    </section>
        <div class="loadingFullPage" v-show="this.loaderForm">Loading&#8230;</div>
    </form>
</template>

<script>
    export default {

        data() {
            return {
                months: [
                    { month: 'January', days: 31 },
                    { month: 'February', days: 28 },
                    { month: 'March', days: 31 },
                    { month: 'April', days: 30 },
                    { month: 'May', days: 31 },
                    { month: 'June', days: 30 },
                    { month: 'July', days: 31 },
                    { month: 'August', days: 31 },
                    { month: 'September', days: 30 },
                    { month: 'October', days: 31 },
                    { month: 'November', days: 30 },
                    { month: 'December', days: 31 },
                ],
                selectedMonth: 'January',
                title: null,
                first_name: null,
                last_name: null,
                address: null,
                apt_suite: null,
                city: null,
                state: null,
                zip_code: null,
                email: null,
                confirm_email: null,
                birth_month: null,
                birth_day: null,
                birth_year: null,
                msg: [],
                formValidated: true,
                loaderForm: false
            };
        },
        computed: {
            filterDays() {
                var vm = this;
                return this.months.filter(function (value) {
                    return value.month === vm.selectedMonth;
                });
            },
            filterYears() {
                const year = new Date().getFullYear();
                return Array.from({ length: year - 1902 }, (value, index) => 1902 + index);
            },
        },
        watch: {

        },
        methods: {
            onChange(event) {
                this.selectedMonth = event.target.value;
            },
            checkForm(e) {
                this.msg = [];
                this.formValidated = true;
                if (! this.title || this.title === 'null') {
                    this.msg['title'] = 'Title required';
                    this.formValidated = false;
                }
                if (! this.first_name || this.first_name === 'null') {
                    this.msg['first_name'] = 'First Name required';
                    this.formValidated = false;
                }
                if (! this.last_name || this.last_name === 'null') {
                    this.msg['last_name'] = 'Last Name required';
                    this.formValidated = false;
                }
                if (! this.address || this.address === 'null') {
                    this.msg['address'] = 'Address required';
                    this.formValidated = false;
                }
                if (! this.apt_suite || this.apt_suite === 'null') {
                    this.msg['apt_suite'] = 'Apt/Suite required';
                    this.formValidated = false;
                }
                if (! this.zip_code || this.zip_code === 'null') {
                    this.msg['zip_code'] = 'Zip Code required';
                    this.formValidated = false;
                }
                if (! this.state || this.state === 'null') {
                    this.msg['state'] = 'State required';
                    this.formValidated = false;
                }
                if (! this.city || this.city === 'null') {
                    this.msg['city'] = 'City required';
                    this.formValidated = false;
                }
                if (! this.email || this.email === 'null') {
                    this.msg['email'] = 'Email required';
                    this.formValidated = false;
                } else if (! this.validateEmail(this.email)) {
                    this.msg['email'] = 'Please provide a valid email';
                    this.formValidated = false;
                }
                if (! this.confirm_email || this.confirm_email === 'null') {
                    this.msg['confirm_email'] = 'Email Confirm required';
                    this.formValidated = false;
                } else if (! this.confirmationEmail(this.email,this.confirm_email)) {
                    this.msg['confirm_email'] = 'Confirm Email and Email do not match.';
                    this.formValidated = false;
                }
                if (! this.birth_month || this.birth_month === 'null') {
                    this.msg['birth_month'] = 'Birth Month required';
                    this.formValidated = false;
                }
                if (! this.birth_day || this.birth_day === 'null') {
                    this.msg['birth_day'] = 'Birth Day required';
                    this.formValidated = false;
                }
                if (! this.birth_year || this.birth_year === 'null') {
                    this.msg['birth_year'] = 'Birth Year required';
                    this.formValidated = false;
                }

                if (this.formValidated) {
                    this.formSubmit();
                } else {
                    e.preventDefault();
                }
                return false;
            },
            validateEmail(email) {
                var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(email);
            },
            confirmationEmail(email,confirm_email) {

                return (email === confirm_email);
            },
            async formSubmit() {
                let vm = this;
                vm.loaderForm = true;
                await $.ajax({
                    method: 'POST',
                    url: route('official-registration.store'),
                    data: {
                        title: this.title,
                        first_name: this.first_name,
                        last_name: this.last_name,
                        address: this.address,
                        apt_suite: this.apt_suite,
                        state: this.state,
                        city: this.city,
                        zip_code: this.zip_code,
                        email: this.email,
                        email_confirmation: this.confirm_email,
                        birth_month: this.birth_month,
                        birth_day: this.birth_day,
                        birth_year: this.birth_year,
                    },

                }).done((response) => {
                    if (response.code === 400) {
                        vm.loaderForm = false;
                        vm.msg['error'] = response.message;
                    } else {
                        window.location.href = route('official-registration.step', response.data);
                    }
                    vm.$forceUpdate();
                }).fail((xhr) => {
                    var errors = xhr.responseJSON.errors;
                    for (const [key, value] of Object.entries(errors)) {
                        vm.msg[key] = value[0];
                    }
                    vm.$forceUpdate();
                });
            },
        },
    };
</script>