<template>
    <p class="uninav__token-center-alltime__tokens-amount uninav__token-balance__amount" data-current="loading" id="tokens_quota_id" data-total="loading">{{ formatPrice(token_balance) }}</p>
</template>

<script>
export default {

    data() {
        return {
            token_balance: window.ThePsh.token_balance
        };
    },

    computed: {

    },

    watch: {

    },

    mounted: function () {

    },

    methods: {

        formatPrice(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        }
    }
}
</script>
