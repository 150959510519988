<template>
    <div>
        <div id="package-wrap">
            <div id="subWrap">
                <div id="package-ob-section">
                    <div class="container">
                        <div id="logo">
                            <img :src="image('tv_sh_logo.png')" alt="">
                        </div>
                        <div class="headerlogo">
                            <img id="header" :src="image(header.first_header_win)"></div>
                        <div class="package-ob-section-content">
                            <div class="package-ob-section-top">
                                <p class="pk_prospect" style="display: block;">Not Everyone Is Seeing This Exclusive
                                    Selection of</p>
                                <p class="special-offer"><span>$5.00 Off Savings</span></p>
                                <p>It's Only Available Here!</p>

                            </div>
                            <div class="package-ob-section-whiteBox">
                                <div class="package-ob-section-segments">
                                    <div class="pk_prospect" style="display: block;">
                                        <ul>
                                            <li><span class="pk_bullet"></span>
                                                {{header.description_win}}

                                            </li>

                                        </ul>
                                        <p class="pk_segment_disclaimer"><span>*</span>Savings off of the Regular PSH
                                            Price are already reflected in the prices of the items in this Notice.</p>
                                    </div>

                                </div>
                            </div>
                            <p class="package-ob-section-dontMiss"><img :src="image('pk_arrow.png')"> Don't Miss Out!
                                <img :src="image('pk_arrow.png')"></p>
                        </div>
                    </div>

                </div>
                <div id="package-order-section" style="display: block; overflow: visible;">

                    <div id="multipageWrap" style="opacity: 1; visibility: inherit; display: block; overflow: visible;">
                        <div id="spctrmOffer1_divPCHComponent" style="overflow: visible;">
                            <div id="spctrmOffer1_ctl00_ctl00_tabPanel" style="overflow: visible;">
                                <div id="mpWrap">
                                    <div class="tabPromo">
                                        <img id="tabPromo_0" alt="Promotion" :src="image(header.third_header_win)"
                                             style="display: block;">
                                    </div>
                                    <div id="spctrmOffer1_ctl00_ctl00_divId">
                                        <div id="spctrmOffer1_ctl00_ctl00_ctl00_tabs">
                                            <div id="spctrmOffer1_ctl00_ctl00_ctl00_tabcontent_1" class="tab tab0"
                                                 style="display: block;">
                                                <div v-for="(category) in categories"
                                                     id="showMoreLikeThisBox_OfferFeatureDiv_1Spectrum2_MLT_1"
                                                     class="showMoreLikeThisBox adSpecWrapped"
                                                     style="background-color: rgb(231, 173, 12);">
                                                    <img id="adSpecWrapper_62650" class="adSpecWrapperImg"
                                                         :src="image(header.thumbnail)" loading="lazy">
                                                    <div id="showMoreLikeThis_spectrumOfferFeatureDiv_1"
                                                         class="showMoreLikeThis">
                                                        <div id="spctrmOffer1_ctl00_ctl00_ctl00_ctl00_featureDiv"
                                                             class="superFeature superfeature mainAdWrap">
                                                            <div id="spctrmOffer1_ctl00_ctl00_ctl00_ctl00_subAdWrapDiv"
                                                                 class="subAdWrap merch">
                                                                <div id="adgroup_62650" class="Header ">
                                                                    <div class="Header-wrap">
                                                                        <div class="headerOne" v-text="index[category.id].name"></div>
                                                                    </div>
                                                                </div>
                                                                <div class="mainContent">
                                                                    <div class="productOverview"
                                                                         aria-label="product image">
                                                                        <img :src="getThumbnail(index[category.id].images)"
                                                                             class="product_image">
                                                                    </div>
                                                                </div>
                                                                <div class="offer offerCap  " id="offerDiv"
                                                                     offercode="B1DR">
                                                                    <div class="offercap-left"><h4>{{index[category.id].name}}</h4>
                                                                        <p class="mpDiscountPrice">PSH Price: <span> <del
                                                                            v-if="index[category.id].regular_price === ''">
                                                                                <b>{{ index[category.id].price * 1.3}}</b>
                                                                            </del>
                                                                            <del v-else>
                                                                                <b> {{ index[category.id].regular_price}}.00 </b>
                                                                            </del></span>
                                                                        </p>
                                                                        <p class="mpPurchacePrice">Now Only: <span>${{ index[category.id].price }}*</span>
                                                                        </p></div>
                                                                    <p class="cartButton">
                                                                        <a role="button" @click="addToCart(product)"
                                                                           class="bml_plus" id="cartItemLink_B1DR_9634"></a>
                                                                    </p>
                                                                </div>

                                                                <div id="show-more_spectrumOfferFeatureDiv_1"
                                                                     class="show-more smlt-shelve-closed">
                                                                    <div class="show-more-panel"
                                                                         id="62650_show-more-panel_spectrumOfferFeatureDiv_1Spectrum2_MLT_1"></div>
                                                                    <div class="show-more-btn"
                                                                         id="62650_show-more-btn_spectrumOfferFeatureDiv_1"><span
                                                                        class="show-more-text1"><a aria-hidden="false"
                                                                                                   @click="toggler(category, true);"

                                                                                                   aria-label="show more products like this"
                                                                                                   role="button" class="more">Show More Products Like This</a></span>
                                                                    </div>
                                                                </div>

                                                                <div v-if="category.Showing" id="show-more_fusionOfferFeatureDiv_2"
                                                                     class="show-more smlt-shelve-opened" >
                                                                    <div class="show-more-panel"
                                                                         id="51658_show-more-panel_fusionOfferFeatureDiv_1A_MLT_2"

                                                                         style="display: block;">
                                                                        <div v-for="(product,i) in category.products" :key="product.id"  class="show-more-box" style="display: inline-block;">
                                                                            <div tabindex="0" aria-label="additional product"
                                                                                 role="link" class="show-more-thumb smthumb3"
                                                                            ><img :src="getThumbnail(product.images)"
                                                                                  class="">
                                                                                <div class="show-more-view"  @click="changeItem(category,product,i)">
                                                                                    View <span class="show-more-plus">+</span></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="show-error-message"
                                                                         style="opacity: 1; display: none;" >
                                                                        Additional Products Are Unavailable At This Time.
                                                                    </div>
                                                                    <div class="show-more-btn"
                                                                         id="51658_show-more-btn_fusionOfferFeatureDiv_2"
                                                                    ><span class="show-more-text1"
                                                                           style="opacity: 0; z-index: 100; display: none;"><a
                                                                        aria-hidden="true"
                                                                        aria-label="show more products like this" tabindex="0"
                                                                        role="button" v-if="!isVisible"
                                                                        @click="isVisible = isVisible">Show More Products Like This</a></span><span
                                                                        class="show-more-text2"
                                                                        style="display: block; z-index: 200; opacity: 1;"><a
                                                                        aria-hidden="false" tabindex="0"
                                                                        aria-label="hide additional products" role="button"
                                                                        @click="toggler(category, false);">Hide Additional Products</a><a
                                                                        aria-hidden="false"></a></span></div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="isModalVisible">
            <transition name="modal">
                <div class="modal-mask popup-addtocart">
                    <div class="modal-wrapper">
                        <div class="modal-container">
                            <div class="modal-header eck-header eck-bar-a">
                                <div class="modal-title eck-title eck-corner-all">Express Checkout</div>
                                <button type="button" class="close" data-dismiss="modal"
                                        @click="isModalVisible = !isModalVisible" aria-hidden="true">×
                                </button>
                            </div>
                            <div class="modal-body">
                                <div id="cartContainer">
                                    <p class="order-total">
                                        <span>{{ cart.quantity }}</span> item(s) in your Cart
                                    </p>
                                    <div id="cart-items-popup">
                                        <div id="mpCart_B1DR" v-for="item in cart.items" :key="item.id"
                                             class="cart-item">
                                            <div class="eckCartDesc">
                                                <p class="eckSlpNoGift"></p>
                                                <h4>{{ item.product.name }}
                                                    <p class="eckCartRemove">
                                                        <a @click="remove(item)" href="javascript:void(0)"
                                                           title="Remove From Cart"></a>
                                                    </p>
                                                </h4>
                                                <p>PSH Price: <span>{{ item.product.price }}*</span></p>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- enabled checkout now button -->
                                    <a class="eck-btn eck-btn-b eck-shadow eck-corner-all"
                                       :href="route('cart.index')">Checkout Now</a>

                                    <a class="eck-btn eck-btn-a eck-shadow eck-corner-all"
                                       @click="isModalVisible = !isModalVisible" href="javascript:;">Continue
                                        Shopping</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>

</template>

<script>
import store from '../../store';

export default {
    name: "ProductsList",
    data() {
        return {
            isModalVisible: false,
            isVisible: false,
            value1: false,
            fetchingProducts: false,
            addingToCart: false,
            product:{},
            index: [],
            header:'',
            category:{},
            header_thumbnail:'',
            categories:{},
            products: [],
            queryParams: {
                query: this.initialQuery,
                brand: this.initialBrandSlug,
                category: this.initialCategorySlug,
                tag: this.initialTagSlug,
                attribute: this.initialAttribute,
                fromPrice: 0,
                toPrice: this.maxPrice,
                sort: this.initialSort,
                perPage: this.initialPerPage,
                page: this.initialPage,
            },
        };
    },
    computed: {
        cart() {
            return store.state.cart;
        },
        emptyProducts() {
            return this.products.data.length === 0;
        },

        totalPage() {
            return Math.ceil(this.products.total / this.queryParams.perPage);
        },

        showingResults() {
            if (this.emptyProducts) {
                return;
            }

            return 'Showing ' + this.products.from + '-' + this.products.to + ' of ' + this.products.total + ' results';

        },
    },
    mounted() {
        this.addEventListeners();
        this.fetchProducts();
    },

    methods: {
        image(img) {

            if (img !== undefined) {
                return window.location.origin + '/images/main/' + img;
            }
        },
        changeItem(category,product,i){
            this.index[category.id] = product;
            this.index.splice(category.id, 1, product);
        },
        addToCart(product) {
            this.addingToCart = true;

            $.ajax({
                method: 'POST',
                url: route('cart.store'),
                data: {product_id: product.id, qty: 1}
            }).then((cart) => {
                store.updateCart(cart);
                this.isModalVisible = true;
            }).catch((xhr) => {
                this.$notify(xhr.responseJSON.message);
            }).always(() => {
                this.addingToCart = false;
            });
        },
        remove(cartItem) {
            store.removeCartItem(cartItem);

            $.ajax({
                method: 'DELETE',
                url: route('cart.remove', {cartItemId: cartItem.id}),
            }).then((cart) => {
                store.updateCart(cart);
            });
        },
        addEventListeners() {
            $(this.$refs.sortSelect).on('change', (e) => {
                this.queryParams.sort = e.currentTarget.value;

                this.fetchProducts();
            });

            $(this.$refs.perPageSelect).on('change', (e) => {
                this.queryParams.perPage = e.currentTarget.value;

                this.fetchProducts();
            });
        },

        changePage(page) {
            this.queryParams.page = page;

            this.fetchProducts();
        },
        toggler(obj, showing){
            this.$set(obj, 'Showing', showing);
        },
        fetchProducts(options = {updateAttributeFilters: true}) {
            this.fetchingProducts = true;
            let vm = this;

            this.$axios
                .get(route('products.index'))
                .then(response => {
                    this.products = response.data.products;
                    this.header = response.data.header;
                    this.categories = response.data.categories;
                    response.data.categories.forEach((value,index ) => {
                        this.product = value.products[0];
                        vm.index[value.id] = value.products[0];
                        value.products.forEach((valuer,index ) => {
                            this.product = value.products[0];
                        })})})
                .catch((xhr) => {
                    this.$notify(xhr.responseJSON.message);
                });

        },

        getThumbnail(ProductImages) {
            let JsonImage = JSON.parse(ProductImages);
            return JsonImage[0].src;
        }
    },
}
</script>

<style>

</style>
