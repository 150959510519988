<template>
    <div class="modal fade" id="setPassword-modal"  tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="sso-lightbox">
                    <div class="da-box">
                        <div>
                            <div class="title">Complete Your Registration</div>
                            <div class="copy">
                                <p><strong class="body-welcome">Hi, {{first_name}}!</strong> You're just one step away from completing your registration and joining our FREE PSHRewards Loyalty Program as a BRONZE member with an EXTRA 1,000 TOKENS!</p>
                                <p>Membership has its benefits, so create a Password now!</p>
                            </div>
                            <div class="fields">
                                <form action="javascript:;" id="setPassword-form" @submit="checkForm">
                                    <label for="password">Password:</label>
                                    <input class="lbpassword required" type="password" v-model="password" name="password" id="password" autocomplete  required placeholder="Password">
                                    <label for="confirm_password">Confirm Password:</label>
                                    <input class="lbconfirmPassword required" type="password" v-model="confirm_password" name="confirm_password" id="confirm_password" autocomplete  required placeholder="Confirm Password">
                                    <div class="options">
                                        <input class="remember" type="checkbox" checked="checked"> Keep me signed in. <br>Not you? <a :href="route('official-registration')">Click here</a>
                                    </div>
                                    <div><span class="floating-placeholder" v-if="msg.error">{{msg.error}}</span></div>
                                    <button class="submit"></button>
                                    <span class="loader-icon"></span>
                                    <a class="sso-forfeit" href="#">No thanks, I want to forfeit my tokens.</a>
                                    <a class="policy" href="http://privacy.thepsh.com" target="_blank">Privacy Policy</a>
                                </form>
                            </div>
                            <div>
                                <div class="prop-sprite">Just One Password is All It Takes...<br> to access any of our Publishers Clearing House sites:<div class="image"></div></div>
                            </div>
                        </div>
                    </div>
                    <button class="close-x" v-on:click="closeModal"></button>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    export default {
        props: ['showmodal','username'],
        data() {
            return {
                password: null,
                confirm_password: null,
                msg: [],
                formValidated: true,
                first_name:this.username
            };
        },
        computed: {

        },
        mounted() {
            if (this.showmodal){
                this.openModal();
            }
        },
        watch: {

        },
        methods: {
            openModal : function () {
                $('#setPassword-modal').modal('show');
            },
            closeModal: function () {
                $('#setPassword-modal').modal('hide');
            },
            confirmationPassword(password,confirm_password) {
                return (password === confirm_password);
            },
            checkForm(e) {
                this.msg = [];
                this.formValidated = true;
                if (! this.password || this.password === 'null') {
                    this.msg['password'] = 'Password Confirm required';
                    this.formValidated = false;
                }
                if (! this.confirm_password || this.confirm_password === 'null') {
                    this.msg['confirm_password'] = 'Password Confirm required';
                    this.formValidated = false;
                } else if (! this.confirmationPassword(this.password,this.confirm_password)) {
                    this.msg['confirm_password'] = 'Confirm Password and Password do not match.';
                    this.formValidated = false;
                }

                if (this.formValidated) {
                    this.setPassword();
                } else {
                    e.preventDefault();
                }
                return false;
            },
            async setPassword() {
                var vm = this;
                await $.ajax({
                    method: 'POST',
                    url: route('official-setPassword.store'),
                    data: {
                        password: this.password,
                        remember_me: this.remember_me,
                    },
                }).done((response) => {
                    console.log(response);
                    if (response.code === 400) {
                        vm.msg['error'] = response.message;
                    } else {
                        vm.closeModal();
                        //window.location.href = response.data;
                    }
                    vm.$forceUpdate();
                }).fail((xhr) => {
                    var errors = xhr.responseJSON.errors;
                    for (const [key, value] of Object.entries(errors)) {
                        vm.msg[key] = value[0];
                    }
                    vm.$forceUpdate();
                });
            },
        },
    };
</script>