<template>
    <div class="menu-item">
        <a :href="route('rewards')">
            {{ title }}
        </a>
        <svg viewBox="0 0 1030 638" width="1">
            <path d="M1017 68L541 626q-11 12-26 12t-26-12L13 68Q-3 49 6 24.5T39 0h952q24 0 33 24.5t-7 43.5z" fill="#FFF"></path>
        </svg>
        <!--<transition name="fade" appear>
            <div class="sub-menu" v-if="isOpen">
                <div class="uninav__reward-center " bis_skin_checked="1">
                    <div class="uninav__reward-center-content uninav__dropdown__content" bis_skin_checked="1">
                        <div class="uninav__token-exchange" bis_skin_checked="1">
                            <h3 class="uninav__token-exchange-title">PCHRewards Token Exchange</h3>
                            <div class="uninav__token-exchange-drawing" bis_skin_checked="1">
                                <div class="uninav__token-exchange-drawing-image" bis_skin_checked="1">

                                    <img src="https://cdn.pch.com/pchcom/rewards/bronze_promotion.png?rulesId=WNYCTripFor2"></div>
                                <p class="uninav__token-exchange-drawing-time">WINNER IS GUARANTEED THIS WEEK</p>
                                <h4 class="uninav__token-exchange-drawing-title">Win A NYC Trip For 2 Or $10,000 Cash Option</h4>
                                <p class="uninav__token-exchange-drawing-body">Imagine enjoying all the beauty and bright lights of the big apple… the city that never sleeps… the one and only New York City – all expenses paid!</p>
                                <p class="uninav__token-exchange-drawing-amount">
                                    ENTER WITH:
                                    <span class="uninav__token-exchange-drawing-tokens">
                        <span class="uninav__token-exchange-drawing-tokens-amt">5,000</span>
                        TOKENS
                    </span>
                                </p>
                            </div>
                        </div>
                        <div class="uninav__redeem" >
                            <div class="uninav__redeem-tokens uninav__token-balance uninav__token-balance&#45;&#45;small" >
                                <p class="uninav__redeem-tokens-label uninav__token-balance__label">MY AVAILABLE TOKENS FOR EXCHANGE</p>
                                <p class="" data-current="loading" data-total="loading"><balance-token></balance-token></p>
                            </div>

                            <a class="uninav__redeem-link&#45;&#45;pchrewards uninav__button uninav__button&#45;&#45;large" :href="route('rewards')">
                                REDEEM TOKENS
                            </a>
                        </div>
                    </div></div></div>

        </transition>-->
    </div>
</template>

<script>
export default {
    name: 'Dropdown',
    props: ['title', 'items'],
    data () {
        return {
            isOpen: false
        }
    }
}
</script>

<style>

 .sub-menu {
    position: absolute;
    background-color: #222;
    top: calc(100% + 18px);
    left: 50%;
    transform: translateX(-50%);
    width: max-content;
    border-radius: 0px 0px 16px 16px;
}
.fade-enter-active,
.fade-leave-active {
    transition: all .5s ease-out;
}
.fade-enter,
.fade-leave-to {
    opacity: 0;
}

</style>
