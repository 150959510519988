<template>
    <a @click="logout" :class="class_href">
        <span>Sign Out</span>
    </a>
</template>

<script>
    export default {
        props: ['class_href'],
        data() {
            return {
                isLogout: false,
            };
        },
        methods: {
            async logout() {
                var vm = this;
                await $.ajax({
                    method: 'POST',
                    url: route('official-logout.index'),
                    data: {},
                }).done((response) => {
                    if (response.code === 400) {
                        vm.msg['error'] = response.message;
                    } else {
                        window.location.href = response.data;
                    }
                    vm.$forceUpdate();
                }).fail((xhr) => {
                    var errors = xhr.responseJSON.errors;
                    for (const [key, value] of Object.entries(errors)) {
                        vm.msg[key] = value[0];
                    }
                    vm.$forceUpdate();
                });
            },
        },
    };
</script>